@import "variables.module";
@import "mixins";

@import "~bootstrap/scss/bootstrap.scss";

body {
	background-color: white;
	font-family: $font-main;
}
a {
	text-decoration: none;
	&:hover {
		color: initial;
		opacity: .7;
		transition: opacity .3s;
	}
}
.kut-uppercase {
	text-transform: uppercase;
}
.kut-lowercase {
	text-transform: lowercase;
}
.kut-flex {
	display: flex;
}
.kut-space-between {
	justify-content: space-between;
}
.kut-direction-column {
	flex-direction: column;
}
.kut-align-items-center {
	align-items: center;
}
.kut-justify-content-center {
	justify-content: center;
}
.kut-text-underline {
	text-decoration: underline;
}
