@import '../../assets/sass/variables.module';

.kut-radio-group-component {
	display: flex;
	gap: 10px;
	.kut-radio-item {
		display: flex;
		gap: 10px;
	}
}
