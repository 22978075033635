@import '../assets/sass/variables.module';

.dashborad-main-container {
    display: flex;
    font-family: Inter;
    color: rgb(var(--text-color));
    min-height: 100vh;
    --side-bar-width: 16%;

    &.fold {
        --side-bar-width: 2%;
    }

    .dashboard-main-body-container {
        width: 100%;
        padding-left: max(var(--side-bar-width), 95px);
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease-in-out;

        .main-body-container {
            padding: 31px 48px 25px 36px;
            flex: 1;
            display: flex;
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 1700px) {
    .dashborad-main-container {
        --side-bar-width: 17%;
    }
}

@media screen and (max-width: 1600px) {
    .dashborad-main-container {
        --side-bar-width: 18%;
    }
}

@media screen and (max-width: 650px) {
    .dashborad-main-container {
        .dashboard-main-body-container {
            transition: none;
        }
    }
}

@media screen and (max-width: 576px) {
    .dashborad-main-container {
        .sidebar-container {
            display: none;
        }

        .dashboard-main-body-container {
            padding-left: 0;
            transition: none;

            .main-body-container {
                padding: 26px;
            }
        }
    }
}