@import '../../assets/sass/variables.module';

.kut-component-main {
	display: flex;
	align-items: center;
	padding-left: 20px;
	position: relative;
	font-size: 12px;
	font-weight: 400;
	width: 100%;
	&.active {
		color: $color-success;
		>.kut-component-mark:nth-child(1) {
			display: block;
		}
	}
	&.inactive {
		color: $color-danger;
		>.kut-component-mark:nth-child(2) {
			display: block;
		}
	}
	> .kut-component-mark {
		position: absolute;
		left: 0;
		display: none;
	}
}