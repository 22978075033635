@import '../../assets/sass/variables.module';

.kut-component-body {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 28px;
    border-radius: 10px 10px 0px 0px;
    p {
        margin: 0px;
    }
    .kut-component-content {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: auto;
        text-align: center;
        border-radius: 10px;
        padding: 25px 60px;
        background-color: rgb(var(--content-background-color));
        .kut-content-title {
            color: $color-primary;
            font-size: 22px;
            font-weight: 600;
            margin: 24px 0px;
        }
        .kut-content-description {
            font-size: 13px;
            font-weight: 500;
            line-height: 22px;
            white-space: pre-line;
            margin-bottom: 16px;
        }
        .kut-content-header {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;
            &::first-letter {
                text-transform: uppercase;
            }
        }
        .kut-content-error {
            color: $color-danger;
        }
        .kut-content-length {
            color: rgb(var(--text-color));
        }
        .kut-content-details {
            background-color: rgb(var(--background-color));
            color: inherit;
            border-radius: 10px;
            padding: 16px 20px;
            height: 225px;
            &:focus {
                outline: none;
            }
            &::placeholder {
                opacity: .5;
            }
        }
        .kut-content-type {
            display: flex;
            justify-content: center;
            gap: 5%;
            margin: 27px 0px;
            font-size: 13px;
            div {
                display: flex;
                align-items: center;
                input {
                    margin-right: 9px;
                    cursor: pointer;
                }
            }
        }
        .kut-content-recaptcha {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
        }
        .kut-content-submit {
            font-size: 16px;
            font-weight: 400;
            line-height: 51px;
            color: rgb(var(--content-background-color));
            text-align: center;
            border-radius: 50px;
            background-color: $color-primary;
            cursor: pointer;
            transition: opacity .3s;
            &:hover {
                opacity: .7;
            }
        }
    }
}

@media screen and (max-width: 1335px) {
    .kut-component-body {
        .kut-component-content {
            width: 75%;
        }
    }
}

@media screen and (max-width: 900px) {
    .kut-component-body {
        .kut-component-content {
            width: 85%;
        }
    }
}

@media screen and (max-width: 790px) {
    .kut-component-body {
        .kut-component-content {
            width: 90%;
            padding: 30px 30px;
        }
    }
}

@media screen and (max-width: 743px) {
    .kut-component-body {
        .kut-component-content {
            .kut-content-description {
                white-space: initial;
            }
        }
    }
}

@media screen and (max-width: 664px) {
    .kut-component-body {
        .kut-component-content {
            margin-top: 5vh;
            .kut-content-type {
                gap: 2%;
            }
        }
    }
}

@media screen and (max-width: 440px) {
    .kut-component-body {
        .kut-component-content {
            margin-top: 2vh;
            width: 100%;
        }
    }
}

@media screen and (max-width: 365px) {
    .kut-component-body {
        .kut-component-content {
            .kut-content-type {
                flex-direction: column;
            }
        }
    }
}
