@import '../../../assets/sass/variables.module';
.kut-component-body {
  font-size: 16px;
  transition: all 0.3s;
  color: rgb(var(--text-color));
  .kut-component-content {
    .kut-component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .kut-component-sort {
        display: flex;
        gap: 10px;
        &.hide {
          visibility: hidden;
        }
        span {
          font-size: 0.688em;
          &.kut-component-sort-option {
            cursor: pointer;
            opacity: 0.4;
            transition: opacity 0.3s;
            font-weight: 400;
            &.active {
              opacity: 1;
              font-weight: 600;
              &:hover {
                opacity: 1;
              }
              &.asc,
              &.ASC {
                &::after {
                  transform: rotate(180deg);
                }
              }
              &::after {
                display: inline-flex;
                margin-left: 2px;
                content: '⮛';
              }
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
      .kut-component-select-all {
        display: flex;
        align-items: center;
        gap: 10px;
        &.hide {
          visibility: hidden;
        }
      }
      .kut-component-switch-view {
        display: flex;
      }
    }
    .kut-component-actions {
      display: flex;
      justify-content: space-between;
      gap: 1em;
      align-items: center;
      .kut-component-buttons {
        font-size: 0.9em;
        &.kut-component-button-delete {
          opacity: 0;
          transition: opacity 0.3s;
          cursor: default;
          &.kut-active {
            opacity: 1;
            cursor: pointer;
          }
        }
        &.kut-buttons-small {
          display: none;
          padding: 0.75em 1em;
        }
      }
      .kut-component-search {
        max-width: 372px;
      }
    }
    .kut-component-table {
      display: none;
      flex-direction: column;
      background-color: rgb(var(--content-background-color));
      padding: 14px 18px;
      border-radius: 10px;
      .kut-component-search {
        background-color: rgb(var(--background-color));
      }
      .table-item-device-name {
        display: flex;
        align-items: center;
        gap: 10px;
        .table-item-device-image {
          border-radius: 100%;
          cursor: pointer;
          min-width: 28px;
        }
        span {
          text-decoration: none;
        }
      }
      .table-item-device-tags {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 5px;
        span {
          text-transform: capitalize;
          text-decoration: none;
          padding: 3px 8px;
          border-radius: 10px;
          color: white;
          background-color: $color-success;
          display: none;
          &.kut-visible {
            display: block;
          }
          &.kut-device-main {
            background-color: $color-warning;
          }
        }
      }
      &.kut-component-active {
        display: flex;
      }
      a {
        &.table-item-view-devices,
        &.table-item-notion-link,
        &.table-item-view-streams {
          color: inherit;
        }
      }
      span {
        text-decoration: underline;
        transition: opacity 0.3s;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
        &.table-item-button-update {
          color: $color-success;
        }
        &.table-item-button-delete {
          color: $color-danger;
        }
      }
    }
    .kut-component-list-view {
      display: none;
      flex-direction: column;
      .kut-component-actions {
        padding: 14px 18px;
      }
      &.kut-component-active {
        display: flex;
      }
      .kut-component-list-item {
        display: flex;
        flex-direction: column;
        .list-item-actions {
          display: flex;
          gap: 3px 10px;
          flex-direction: column;
          .list-item-action-items {
            display: flex;
            flex-direction: column;
            font-size: 0.656em;
            overflow: hidden;
            > div {
              cursor: pointer;
              display: flex;
              gap: 2px;
              span {
                text-decoration: none;
                transition: none;
                &:hover {
                  opacity: 1;
                }
              }
            }
          }
          .list-item-action-buttons {
            display: flex;
            gap: inherit;
            justify-content: flex-end;
            font-size: 0.656em;
          }
        }
        .list-item-heading {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 10px;
        }
        .list-item-views {
          display: flex;
          align-items: center;
          margin: 1em 0;
          .list-item-type {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            margin-right: 0.5em;
            min-width: 2em;
            max-width: 2em;
            height: 2em;
            cursor: pointer;
            &.Prosumer {
              background-color: $color-primary-little-light;
              color: $color-primary;
            }
            &.Producer {
              background-color: $color-success-light;
              color: $color-success;
            }
            &.Consumer {
              background-color: $color-warning-light;
              color: $color-warning;
            }
          }
          .list-item-views-link {
            display: flex;
            flex-direction: column;
          }
        }
        .list-item-name {
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          gap: 10px;
          .list-item-device-image {
            border-radius: 100%;
            cursor: pointer;
            min-width: 28px;
          }
          > div {
            display: flex;
            flex-direction: column;
            white-space: pre-wrap;
            div {
              font-size: 0.9em;
              text-decoration: none;
              &.list-item-created {
                font-size: 0.6em;
                span {
                  text-decoration: none;
                }
                &.active {
                  span {
                    color: $color-primary;
                  }
                }
                &.inactive {
                  span {
                    color: $color-danger;
                  }
                }
              }
              &.list-item-device-tags {
                display: flex;
                align-items: center;
                height: 100%;
                gap: 5px;

                div {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  font-size: 0.7em;
                }

                span {
                  text-transform: capitalize;
                  text-decoration: none;
                  padding: 0.2em 0.5em;
                  border-radius: 10px;
                  color: white;
                  background-color: $color-success;
                  display: none;

                  &.kut-visible {
                    display: block;
                  }

                  &.kut-device-main {
                    background-color: $color-warning;
                  }
                }
              }
            }
          }
        }
        a {
          &.list-item-view-streams {
            color: inherit;
            font-size: 0.9em;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        span {
          text-decoration: underline;
          transition: opacity 0.3s;
          cursor: pointer;
          &:hover {
            opacity: 0.5;
          }
          &.list-item-button-update {
            color: $color-success;
          }
          &.list-item-button-delete {
            color: $color-danger;
          }
        }
      }
    }
  }
}

.kut-modal-form {
  .kut-title {
    font-weight: 600;
    text-align: center;
  }
  > div {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  .kut-input-form {
    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      * {
        margin: 0px;
      }
      > :first-child {
        min-width: 90px;
        max-width: 90px;
      }
    }
  }
}

.kut-create-form,
.kut-update-form {
  .kut-form-device-type {
    display: flex;
    align-items: center;
    .kut-form-device-type-label {
      font-size: 0.625em;
      color: rgba(var(--text-color), 0.5);
    }
    .kut-form-device-type-select {
      border-radius: 10px;
      width: 100%;
      .kut-form-device-type-select {
        font-size: 0.75em;
        border-radius: 10px;
        .kut-form-device-type-item {
          display: flex;
          gap: 10px;
          font-size: 0.75em;
          align-items: center;
          .item-image {
            border-radius: 100%;
          }
        }
      }
    }
  }
  .kut-form-device-tag {
    display: flex;
    align-items: center;
    .kut-form-device-tag-label {
      font-size: 0.625em;
      color: rgba(var(--text-color), 0.5);
    }
    .kut-form-device-tag-select {
      border-radius: 10px;
      width: 100%;
      .kut-form-device-tag-select {
        font-size: 0.75em;
        border-radius: 10px;
        .kut-form-device-tag-item {
          display: flex;
          gap: 10px;
          font-size: 0.75em;
          align-items: center;
          .item-image {
            border-radius: 100%;
          }
        }
      }
    }
  }
  .kut-form-device-ean-status {
    display: flex;
    font-size: 0.625em;
    line-height: 3.2em;
    color: rgba(var(--text-color), 0.5);
    align-items: center;
  }
}
.kut-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.kut-delete-form {
  .kut-form-delete-forever {
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    gap: 10px;
    input {
      margin-right: 0.5em;
    }
  }
}

.kut-form-delete-forever-description {
  font-size: 14px;
  color: red;
}

@media screen and (max-width: 1010px) {
  .kut-component-body {
    font-size: 14px;
    .kut-component-content {
      .kut-component-list-view {
        .kut-component-list-view-main {
          grid-template-columns: 49% 49%;
        }
      }
    }
  }
}

@media screen and (max-width: 725px) {
  .kut-component-body {
    font-size: 12px;
    .kut-component-content {
      .kut-component-table {
        .kut-component-actions {
          .kut-component-buttons {
            &.kut-buttons-large {
              display: none;
            }
            &.kut-buttons-small {
              display: initial;
            }
          }
        }
      }
      .kut-component-list-view {
        .kut-component-list-view-main {
          grid-template-columns: 100%;
        }
        .kut-component-actions {
          .kut-component-buttons {
            &.kut-buttons-large {
              display: none;
            }
            &.kut-buttons-small {
              display: initial;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .kut-component-body {
    .kut-component-content {
      .kut-component-table {
        display: none;
      }
      .kut-component-list-view {
        .kut-component-list-view-main {
          grid-template-columns: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .kut-component-body {
    .kut-component-content {
      .kut-component-table {
        display: none;
      }
    }
  }
}
