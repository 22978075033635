@import '../../../assets/sass/variables.module';

.kut-reset-form {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	.kut-reset-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
		max-width: 477px;
		background-color: rgb(var(--content-background-color));
		margin: 0px 21px;
		padding: 67px;
		border-radius: 10px;
		.kut-logo {
			margin-bottom: 61px;
		}
		.kut-reset-content {
			display: flex;
			flex-direction: column;
			input {
				border: 0.15px solid $color-default-little-light;
				border-radius: 30px;
				padding: 15px 28px;
				width: 100%;
				margin-bottom: 20px;
				&:focus {
					outline: none;
					border: 1px solid $color-primary;
				}
				&::placeholder {
					opacity: .5;
				}
			}
		}
		.kut-reset-actions {
			display: flex;
			width: 100%;
			margin-top: 16px;
			.kut-reset-button {
				display: flex;
				justify-content: center;
				width: 100%;
				padding: 12px 0px;
				font-weight: 400;
				font-size: 16px;
				background-color: $color-primary;
				border-radius: 44px;
				color: white;
				cursor: pointer;
				text-align: center;
			}
		}
	}
	.kut-reset-footer {
		position: absolute;
		color: white;
		bottom: 30px;
	}
	.kut-signin {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex: 1;
		align-items: center;
		padding: 0px 21px;
		width: 100%;
		box-sizing: border-box;

		.kut-signin-form {
			display: flex;
			flex-direction: column;
			background-color: rgb(var(--content-background-color));
			width: 100%;
			padding: 67px 67px 20px 67px;
			border-radius: 10px;
			max-width: 477px;

			.kut-signin-logo {
				display: flex;
				justify-content: center;
				margin-bottom: 61px;
			}

			.kut-signin-content {
				display: flex;
				flex-direction: column;
				gap: 16px;
				.kut-signin-credentials,
				.kut-signin-actions {
					display: flex;
					flex-direction: column;
					gap: 16px;
				}

				.kut-signin-error {
					color: $color-danger;
					display: none;

					&.show {
						display: block;
					}
				}

				.kut-signin-button {
					width: 100%;
					padding: 12px 0px;
					font-weight: 400;
					font-size: 16px;
					background-color: $color-primary;
					border-radius: 44px;
					color: rgb(var(--content-background-color));
					cursor: pointer;
					text-align: center;
				}

				.kut-signin-remember-me {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 5px;
					font-size: 14px;
				}

				.kut-signin-forgot-password {
					margin-top: 10px;
					display: flex;
					justify-content: center;
					text-decoration: underline;
					color: $color-primary;
					font-size: 16px;
					font-weight: 400;
					line-height: 26px;
				}
			}
		}

		.kut-signin-footer {
			position: absolute;
			color: white;
			bottom: 30px;
			font-weight: 400;
			font-size: 13px;
		}
	}
}

@media screen and (max-width: 576px) {
	.kut-signin-body {
		.kut-signin {
			height: inherit;
			padding-bottom: 45px;
			.kut-signin-form {
				height: inherit;
				background-color: transparent;
				padding: 0px;
				.kut-signin-logo {
					margin-top: 105px;
					margin-bottom: 98px;
				}
				.kut-signin-content {
					flex: 1 1;
					justify-content: space-between;
					padding-bottom: 70px;
				}
			}
			.kut-signin-footer {
				opacity: .5;
				font-size: 9px;
			}
		}
	}
}
