@import "../../assets/sass/variables.module";

.main-body-modal-container {
	position: fixed;
	justify-content: space-between;
	display: flex;
	left: 0;
	top: -1000px;
	width: 100%;
	height: 0;
	opacity: 0;
	z-index: -1;
	padding: 22px 13px;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color .3s;
	&.active {
		opacity: 1;
		top: 0px;
		z-index: 1;
		height: 100vh;
		background-color: rgba(0, 0, 0, .3);
		.modal-item-container {
			.modal-item-list {
				animation-name: modal-show;
				animation-duration: .3s;
			}
		}
	}
	.modal-item-container {
		position: relative;
		width: 100%;
		.modal-item-list {
			position: absolute;
			bottom: 0px;
			background-color: rgb(var(--content-background-color));
			padding: 25px 20px;
			width: 100%;
			border-radius: 10px;
			.kut-modal-item {
				font-size: 25px;
				color: rgb(var(--color-text));
				&.active {
					color: $color-primary;
				}
				span {
					margin-left: 16px;
					font-size: 20px;
					font-weight: 600;
				}
			}
		}
	}
}

@keyframes modal-show {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
