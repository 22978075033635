@import '../../assets/sass/variables.module';


.kut-chart-tooltip-component {
	border-radius: 3px;
	opacity: 1;
	transition: all .3s;
	position: absolute;
	pointer-events: none;
	white-space: nowrap;
	&.arrow-left {
		transform: translate(-100%, -50%);
		.kut-chart-tooltip-arrow {
    	right: 0px;
			&::before {
				border-width: 6px 0 6px 6px;
				border-left-color: rgba(0, 0, 0, .7);
			}
		}
	}
	&.arrow-right {
		transform: translate(0, -50%);
		.kut-chart-tooltip-arrow {
    	left: 0px;
			&::before {
				border-width: 6px 6px 6px 0px;
				border-right-color: rgba(0, 0, 0, .7);
			}
		}
	}
	.kut-chart-tooltip-arrow {
		position: absolute;
    top: calc(50% - 6px);
    width: 6px;
    height: 12px;
		&::before {
			position: absolute;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
	.kut-chart-tooltip-inner {
		color: white;
		background-color: rgba(0, 0, 0, .7);
		padding: 6px;
		.kut-chart-tooltip-title {
			display: block;
		}
		.kut-chart-tooltip-content {
			> div {
				display: flex;
				gap: 10px;
				align-items: center;
			}
			.kut-chart-tooltip-icon {
				display: inline-flex;
				border-width: 2px;
				height: 10px;
				width: 10px;
			}
			.kut-chart-tooltip-texts {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
