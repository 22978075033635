@import '../../assets/sass/variables.module';

.kut-switch-view {
    display: flex;
    align-items: center;
    font-size: 11px;
    margin-right: 3px;
    span {
        margin-right: 5px;
    }
    svg {
        font-size: 13px;
        margin-left: 15px;
        opacity: .3;
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }
    .kut-active {
        opacity: 1;
        transition: opacity .3s;

        &:hover {
            opacity: 1;
        }
    }
}
