@import '../../assets/sass/variables.module';

.kut-table {
	width: 100%;
	border-radius: 0px !important;
	> div > div > div > div > div > div * {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	select {
		option {
			color: rgb(var(--text-color));
			background-color: rgb(var(--content-background-color));
		}
	}
}