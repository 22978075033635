@import '../../../assets/sass/variables.module';

.kut-intro-register-body {
    background-image: url('../../../assets/images/background1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $color-default;
    p {
        margin: 0;
        white-space: pre-wrap;
    }
    .kut-intro-register {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        align-items: center;
        padding: 21px;
        &.kut-device-mobile {
            .kut-intro-register-form {
                padding: 47px 32px 40px 32px;
                .kut-intro-register-vector {
                    margin-top: 0px;
                    img {
                        width: 58px;
                        height: 58px;
                    }
                }
                .kut-intro-register-title {
                    font-size: 23px;
                    max-width: 290px;
                    letter-spacing: 0px;
                }
                .kut-intro-register-description {
                    font-size: 15px;
                    max-width: 275px;
                }
            }
            .kut-intro-register-logo {
                margin-bottom: 98px;
            }
            .kut-intro-register-text {
                color: $color-primary-light;
                a {
                    color: rgb(var(--content-background-color));
                }
            }
            .kut-intro-register-footer {
                opacity: .5;
            }
        }
        .kut-intro-register-logo {
            width: 245px;
            height: 42px;
        }
        .kut-intro-register-button {
            margin-top: 50px;
            width: 100%;
            padding: 12px 72px;
            font-weight: 400;
            font-size: 16px;
            background-color: $color-primary;
            border-radius: 44px;
            text-align: center;
            cursor: pointer;
            color: rgb(var(--content-background-color));
            max-width: 477px;
        }
        .kut-intro-register-text {
            margin-top: 12px;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            > span {
                padding-left: 5px;
                > a {
                    text-decoration: underline;
                    color: $color-primary;
                }
            }
        }
       .kut-intro-register-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: rgb(var(--content-background-color));
            padding: 65px 65px 45px 65px;
            border-radius: 10px;
            max-width: 477px;
            width: 100%;
            .kut-intro-register-vector {
                margin-top: 33px;
                img {
                    width: 36px;
                    height: 36px;
                }
            }
            .kut-intro-register-title {
                margin-top: 24px;
                font-weight: 700;
                font-size: 21px;
                max-width: 320px;
                text-align: center;
                letter-spacing: -0.5px;
            }
            .kut-intro-register-description {
                margin-top: 12px;
                text-align: center;
                font-size: 12px;
                max-width: 240px;
            }
            .kut-intro-register-forgot-password {
                margin-top: 35px;
                display: flex;
                justify-content: center;
			    text-decoration: underline;
            }
       }
       .kut-intro-register-footer {
            position: absolute;
            color: rgb(var(--content-background-color));
            bottom: 30px;
            text-align: center;
            padding: 0px 21px;
            font-size: 8.8px;
       } 
    }
}
