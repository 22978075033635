@import '../../assets/sass/variables.module';

.kut-list-view {
	display: grid;
  gap: 10px;
	grid-template-columns: 33% 33% 33%;
	.kut-list-item {
		flex: 1 1;
		background-color: rgb(var(--content-background-color));
		padding: 14px 18px;
		border-radius: 10px;
	}
	.kut-list-loading {
		flex: 100%;
		justify-content: center;
		align-items: center;
		background-color: rgb(var(--content-background-color));
		border-radius: 10px;
	}
}

@media screen and (max-width: 962px) {
	.kut-list-view {
		display: grid;
		gap: 10px;
		grid-template-columns: 49% 49%;
	}
}

@media screen and (max-width: 576px) {
	.kut-list-view {
		display: grid;
		gap: 10px;
		grid-template-columns: 100%;
	}
}
