@import "../../assets/sass/variables.module";
.sidebar-container {
	width: var(--side-bar-width);
	min-width: 95px;
	border-right: 0.15px solid $color-border-grey;
	transition: all 0.3s ease-in-out;
	background-color: rgb(var(--content-background-color));
	position: fixed;
	z-index: 1;

	.sidebar-scrollable-section {
		padding: 59px 23px 50px 23px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100vh;
		overflow: auto;
		overflow-x: hidden;
	}

	.top-sidebar-section {
		display: flex;
		flex-direction: column;

		.logo-img {
			display: flex;
			flex-direction: column;
			align-items: center;
			min-height: 116px;
			text-align: center;
			img {
				max-width: 204px;
				width: 100%;
				height: auto;
				cursor: pointer;
			}
		}
	}

	.menu-list-wrapper {
		display: flex;
		flex-direction: column;
		font-size: 15px;
		font-weight: 600;
		transition: width .5s;

		.menu-list-item {
			padding: 15px;
			cursor: pointer;
			position: relative;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.menu-list-item-text {
				text-transform: capitalize;
			}

			svg {
				margin-right: 15px;
				font-size: 20px;
			}

			.unread-count-wrapper {
				border-radius: 20px;
				padding: 2px 6px;
				background-color: $color-warning;
				font-size: 10px;
				color: white;
				margin-left: 15px;
			}
		}

		.menu-list-item.active {
			border-radius: 50px;
			background-color: rgba(var(--active-item-color), .5);
			color: white;
		}
	}

	.bottom-sidebar-section {
		display: flex;
		flex-direction: column;
	}

	.sidebar-fold-button {
		position: absolute;
		width: 18px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 10px;
		font-weight: 700;
		background-color: rgb(var(--text-color));
		color: rgb(var(--content-background-color));
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		opacity: .5;
		right: -19px;
		bottom: 50px;
		cursor: pointer;
	}
	&.fold {
	
		.sidebar-container {
			padding-left: 20px;
			padding-right: 20px;
	
			.logo-img {
				padding-left: 0px;
			}
	
			.menu-list-wrapper {
				width: 52.5px;
				text-align: center;
	
				svg {
					margin-right: 0px;
				}
	
				.unread-count-wrapper {
					position: absolute;
					width: 6px;
					height: 6px;
					padding: 0;
					border: 1px solid rgb(var(--background-color));
					left: calc(50% - 10px);
					bottom: 20px;
				}
			}
		}
	}
}
