@import '../../assets/sass/variables.module';

.kut-component-body {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    border-radius: 0.625em;
    p {
        margin: 0em;
    }
    .kut-user-select-plan {
        display: flex;
        flex-direction: column;
        .kut-link {
            text-decoration: underline;
            cursor: pointer;
            transition: opacity .3s;
            &:hover {
                opacity: .7;
            }
        }
        .kut-select-plan-back-link {
            font-size: 0.75em;
            color: $color-primary;
            margin-bottom: 1em;
        }
        .kut-select-plan-title {
            font-size: 1.1em;
            margin-bottom: 1.3em;
        }
        .kut-select-plan-content {
            display: flex;
            justify-content: center;
            gap: 1em 2%;
            > div {
                display: flex;
                width: 32%;
                padding: 1.875em 1em;
                justify-content: center;
                border-radius: 10px;
                background-color: rgb(var(--content-background-color));
                * {
                    text-align: center;
                }
                &.active {
                    background-color: var(--color-background);
                    .kut-select-plan-item-content {
                        .kut-select-plan-item-type, 
                        .kut-select-plan-item-category .kut-select-plan-item-category-title{
                            mix-blend-mode: multiply;
                        }
                        .kut-pro-span {
                            color: var(--color-background);
                            background-color: rgb(var(--content-background-color));
                            border-radius: 3px;
                        }
                        .kut-select-plan-item-link {
                            color: $color-primary-strong-light;
                        }
                    }
                }
                .kut-select-plan-item-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-weight: 500;
                    .kut-select-plan-item-name {
                        font-size: 1.5em;
                        color: var(--color-title-price);
                        margin-bottom: 0.3em;
                        max-width: 5.5em;
                        min-height: 3em;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        position: relative;
                    }
                    .kut-select-plan-item-type {
                        font-size: 0.75em;
                        color: var(--color-text);
                    }
                    .kut-select-plan-item-price {
                        font-size: 3.125em;
                        color: var(--color-title-price);
                    }
                    .kut-select-plan-item-category {
                        font-size: 0.75em;
                        margin-bottom: 1em;
                        color: var(--color-text);
                        &.inactive {
                            color: $color-default-middle-light;
                        }
                    }
                    .kut-select-plan-item-button {
                        font-size: 0.938em;
                        margin-top: 1.333em;
                    }
                    .kut-select-plan-item-link {
                        font-size: 0.813em;
                        margin-top: 1em;
                        color: rgb(var(--text-color));
                    }
                    .kut-pro-span {
                        text-transform: uppercase;
                        font-size: 0.7em;
                        padding: 0.2em 0.3em;
                        margin-left: 0.5em;
                        color: white;
                        background-color: $color-secondary;
                        border-radius: 3px;
                        position: absolute;
                    }
                }
            }
            .kut-select-plan-participant {
                --color-title-price: rgb(#{red($color-primary), green($color-primary), blue($color-primary)});
                --color-text: rgb(var(--text-color));
                --color-background: rgb(var(--content-background-color));
                &.active {
                    --color-background: rgb(#{red($color-primary), green($color-primary), blue($color-primary)});
                    --color-title-price: rgb(var(--content-background-color));
                }
            }
            .kut-select-plan-community-manager {
                --color-title-price: rgb(#{red($color-primary), green($color-primary), blue($color-primary)});
                --color-text: rgb(var(--text-color));
                --color-background: rgb(var(--content-background-color));
                &.active {
                    --color-background: rgb(#{red($color-primary), green($color-primary), blue($color-primary)});
                    --color-title-price: rgb(var(--content-background-color));
                }
            }
            .kut-select-plan-community-manager-pro {
                --color-title-price: rgb(#{red($color-secondary), green($color-secondary), blue($color-secondary)});
                --color-text: rgb(#{red($color-secondary), green($color-secondary), blue($color-secondary)});
                --color-background: rgb(var(--content-background-color));
                &.active {
                    --color-background: rgb(#{red($color-secondary), green($color-secondary), blue($color-secondary)});
                    --color-title-price: rgb(var(--content-background-color));
                    --color-text: rgb(var(--content-background-color));
                }
            }
        }
    }
    .kut-component-header {
        height: 10.625em;
        background: center / cover no-repeat url('../../assets/images/background1.png');
        border-radius: 0.625em 0.625em 0em 0em;
    }
    .kut-component-content {
        display: flex;
        flex-direction: column;
        padding: 0em 4.0625em;
        background-color: rgb(var(--content-background-color));
        hr {
            margin: 1em 0em;
        }
        .kut-user-avatar-div {
            transform: translateY(-3.75em);
            display: flex;
            align-items: flex-end;
            position: relative;
            gap: 1.25em;
            .kut-user-avatar-img {
                width: 7.5em;
                height: 7.5em;
                border: 0.3125em solid rgb(var(--content-background-color));
                border-radius: 100%;
            }
            .kut-user-name-email {
                .kut-user-name {
                    font-size: 1.1875em;
                    font-weight: 600;
                    line-height: 1.875em;
                }
                .kut-user-email {
                    font-size: 0.75em;
                    font-weight: 500;
                    line-height: 0.9375em;
                    opacity: .5;
                }
            }
            .kut-user-public-profile {
                position: absolute;
                right: 0em;
                font-size: 0.6875em;
                font-weight: 400;
                color: inherit;
            }
        }
        .kut-nav-bar::-webkit-scrollbar {
            display: none;
        }
        .kut-nav-bar {
            display: flex;
            justify-content: flex-start;
            font-size: 0.75em;
            font-weight: 400;
            overflow-x: overlay;
            overflow-y: hidden;
            -ms-overflow-style: none;
            scrollbar-width: none;
            padding-bottom: 0.125em;
            div {
                &:not(:last-child) {
                    padding-right: 1.5625em;
                }
                border-bottom: 0.15px solid $color-default-little-light;
                cursor: pointer;
                transition: color .3s;
                white-space: nowrap;
                &:hover {
                    color: black;
                }
                span {
                    margin-bottom: -0.125em;
                }
                &.active {
                    border-bottom-color: transparent;
                    margin-bottom: calc(-0.125em - 0.075px);
                    padding-right: 0px;
                    span::after {
                        display: block;
                        content: ' ';
                        height: 0.25em;
                        margin-top: 0.7em;
                        border-radius: 0.125em;
                        background-color: $color-primary;
                    }
                }
                &.active-right {
                    padding-left: 1.5625em;
                }
                &.nav-last-item {
                    width: 100%;
                }
            }
        }
        .kut-user-profile {
            display: flex;
            flex-direction: column;
            .kut-user-avatar {
                display: flex;
                flex-direction: column;
                margin-bottom: 0.4em;
                .kut-content {
                    display: flex;
                    gap: 1.5em;
                    padding: 0em;
                    max-height: none;
                    background-color: rgb(var(--content-background-color));
                    .kut-avatar-image {
                        min-width: 4em;
                        max-width: 4em;
                        min-height: 4em;
                        max-height: 4em;
                        border-radius: 100%;
                    }
                    .kut-avatar-actions {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .kut-avatar-actions-buttons {
                            display: flex;
                            gap: 2em;
                            color: $color-primary;
                        }
                    }
                }
            }
            .kut-user-info,
            .kut-user-company {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .kut-user-info {
                margin-bottom: 0.6em;
                > div {
                    &.kut-user-info-pronouns {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .kut-content {
                            padding: 0em;
                            svg {
                                width: 1em;
                                height: 1em;
                            }
                        }
                    }
                }
            }
            .kut-hr-bottom-none {
                margin-bottom: 0.2em;
            }
            .kut-bottom-form {
                .kut-profile-bottom-status {
                    display: flex;
                    font-size: 0.625em;
                    gap: 1em 4em;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    p {
                        &:last-child {
                            color: $color-danger;
                            svg {
                                font-size: 2em;
                            }
                        }
                    }
                }
                .kut-bottom-actions {
                    display: flex;
                    gap: 0.813em;
                }
            }
        }
        .kut-user-billing-plan {
            display: flex;
            flex-direction: column;
            .kut-billing-title {
                font-size: 0.625em;
                opacity: .5;
                line-height: 3em;
            }
            .kut-billing-current-plan {
                display: flex;
                flex-direction: column;
                .kut-billing-plan-content {
                    display: flex;
                    gap: 1.5em;
                    align-items: center;
                    .kut-billing-current {
                        width: 20.25em;
                        height: 3.125em;
                        font-size: 1em;
                        padding-left: 0.875em;
                        display: flex;
                        align-items: center;
                        color: white;
                        background-color: $color-primary;
                        border-radius: 0.625em;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .kut-billing-actions {
                        display: flex;
                        flex-direction: column;
                        .kut-billing-action-buttons {
                            display: flex;
                            gap: 0.875em;
                            span {
                                font-size: 0.75em;
                                color: $color-primary;
                                cursor: pointer;
                                transition: all .3s;
                                text-decoration: underline;
                                text-decoration-color: transparent;
                                &:hover {
                                    text-decoration-color: inherit;
                                }
                            }
                        }
                        .kut-billing-action-text {
                            font-size: 0.75em;
                            opacity: .5;
                        }
                    }
                }
            }
            .kut-billing-person {
                .kut-billing-person-content {
                    display: flex;
                    align-items: center;
                    padding-left: 1.125em;
                    font-size: 0.75em;
                    max-width: 27em;
                    line-height: 3.333em;
                    border-radius: 0.625em;
                    background-color: rgb(var(--background-color));
                    color: rgba(var(--text-color), .7);
                }
            }
            .kut-billing-update-information {
                display: flex;
                gap: 1em;
                margin-top: 0.875em;
                align-items: center;
                .kut-billing-update-link {
                    font-size: 0.75em;
                    color: $color-purple;
                    text-decoration: underline;
                }
                .kut-billing-update-text {
                    font-size: 0.675em;
                    opacity: .5;
                }
                .kut-billing-update-company {
                    width: 5em;
                    height: 2.375em;
                }
            }
            .kut-billing-history {
                display: flex;
                flex-direction: column;
            }
            .kut-bottom-form {
                p {
                    font-size: 0.625em;
                }
                .kut-bottom-actions {
                    display: flex;
                    gap: 0.813em;
                }
            }
        }
        .kut-user-notification {
            display: flex;
            flex-direction: column;
            .kut-notification-title {
                font-size: 0.625em;
                opacity: .5;
            }
            .kut-notification-disable-all-title {
                line-height: 3.8em;
            }
            .kut-notification-disable-all {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 20.25em;
                height: 2.5em;
                color: $color-primary;
                background-color: $color-primary-little-light;
                border-radius: 0.625em;
                svg {
                    font-size: 1.063em;
                    margin-right: 0.3em;
                }
                span {
                    font-size: 0.75em;
                }
            }
            .kut-notification-configuration {
                margin-top: 1.5em;
                margin-bottom: 0.35em;
            }
            .kut-notification-events {
                display: flex;
                flex-direction: column;
                margin-top: 1em;
                .kut-notification-events-title {
                    font-size: 0.75em;
                    font-weight: 500;
                    margin-top: 0.438em;
                }
                .kut-notification-events-details {
                    font-size: 0.675em;
                    font-weight: 500;
                    color: rgba(var(--text-color), .7);
                }
            }
            .kut-bottom-form {
                p {
                    font-size: 0.625em;
                }
                .kut-bottom-actions {
                    display: flex;
                    gap: 0.813em;
                }
            }
        }
        .kut-user-security {
            display: flex;
            flex-direction: column;
            .kut-security-title {
                font-size: 0.625em;
                opacity: .5;
            }
            .kut-security-communities {
                line-height: 3.8em;
            }
            .kut-security-communities-button {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 20.25em;
                height: 2.5em;
                color: $color-primary;
                background-color: $color-primary-little-light;
                border-radius: 0.625em;
                margin-bottom: 0.75em;
                svg {
                    font-size: 1.375em;
                    margin-right: 0.3em;
                }
                span {
                    font-size: 0.75em;
                    font-weight: 700;
                }
            }
            .kut-security-download-data {
                color: $color-primary;
                text-decoration: underline;
                font-size: 0.75em;
                font-weight: 500;
            }
            .kut-security-download-data-description {
                font-size: 0.75em;
                opacity: .5;
            }
            .kut-security-password-devider {
                margin: 1em 0em 0.375em 0em;
            }
            .kut-security-password, 
            .kut-security-level {
                display: flex;
                gap: 2%;
                justify-content: space-between;
            }
            .kut-security-password {
                flex-wrap: wrap;
            }
            .kut-security-level {
                flex-direction: column;
                .kut-security-level-form {
                    display: flex;
                    justify-content: space-between;
                    gap: 2%;
                    flex-wrap: wrap;
                    .kut-security-level-title {
                        font-size: 0.75em;
                        line-height: 2.5em;
                    }
                    .kut-security-level-content {
                        font-size: 0.625em;
                        opacity: .7;
                    }
                }
            }
            .kut-bottom-form {
                p {
                    font-size: 0.625em;
                }
                .kut-bottom-actions {
                    display: flex;
                    gap: 0.813em;
                }
            }
        }
        .kut-user-langapp {
            display: flex;
            flex-direction: column;
            .kut-langapp-config {
                display: flex;
                gap: 2%;
                .kut-langapp-config-lang {
                    .kut-content {
                        padding: 0em;
                    }
                }
                .kut-langapp-config-logo {
                    display: flex;
                    flex-direction: column;
                    .kut-langapp-config-logo-div {
                        display: flex;
                        gap: 1em;
                        img {
                            background-color: rgb(var(--background-color));
                            border-radius: 0.625em;
                            padding: 0.75em 2em;
                            width: 9.5em;
                            min-height: 2.5em;
                            max-height: 2.5em;
                        }
                        .kut-langapp-config-logo-actions {
                            display: flex;
                            flex-direction: column;
                            font-size: 0.75em;
                            justify-content: space-between;
                            .kut-langapp-config-logo-action-buttons {
                                display: flex;
                                gap: 1.333em;
                            }
                            .kut-langapp-config-logo-description {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
            .kut-langapp-theme {
                display: flex;
                flex-direction: column;
                margin-top: 0.5em;
                .kut-langapp-theme-content {
                    display: flex;
                    gap: 1.25em;
                    > div {
                        display: flex;
                        flex-direction: column;
                        gap: 0.875em;
                        cursor: pointer;
                        transition: all;
                        &:hover {
                            p {
                                opacity: 1;
                            }
                            > div::before {
                                opacity: .2;
                            }
                        }
                        &.active:not(:hover) p {
                            opacity: .7;
                        }
                        &.kut-langapp-theme-light div {
                            &::before {
                                background-color: black;
                            }
                        }
                        &.kut-langapp-theme-dark div {
                            border-color: black;
                            &::before {
                                background-color: white;
                            }
                        }
                        p {
                            font-size: 0.75em;
                            opacity: .2;
                            transition: all .3s;
                        }
                        img {
                            min-width: 9.5em;
                            max-width: 9.5em;
                            max-height: 6.125em;
                            height: auto;
                            border-radius: 0.625em;
                        }
                        > div {
                            border-radius: 0.625em;
                            position: relative;
                            box-shadow: 3em 2.75em 1.625em rgba(0, 0, 0, 0.01),
                                        1.6875em 1.5625em 1.375em rgba(0, 0, 0, 0.03),
                                        0.75em 0.6875em 1.0625em rgba(0, 0, 0, 0.04),
                                        0.125em 0.125em 0.5em rgba(0, 0, 0, 0.05),
                                        0em 0em 0em rgba(0, 0, 0, 0.05);
                            &::before {
                                display: flex;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                content: ' ';
                                opacity: 0;
                                transition: all .3s;
                                border-radius: 0.625em;
                            }
                        }
                    }
                }
            }
            .kut-langapp-automation {
                display: flex;
                flex-direction: column;
                margin-top: 1.625em;
                .kut-langapp-title {
                    font-size: 0.75em;
                    line-height: 2.5em;
                }
                .kut-langapp-content {
                    font-size: 0.625em;
                    opacity: .7;
                }
            }
            .kut-bottom-form {
                p {
                    font-size: 0.625em;
                    span {
                        color: $color-primary;
                    }
                }
                .kut-bottom-actions {
                    display: flex;
                    gap: 0.813em;
                }
            }
        }
        .kut-bottom-form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1.25em;
            margin-bottom: 1.25em;
            gap: 0.625em;
        }
        .kut-input-form {
            width: 32%;
            > div {
                align-items: flex-start;
                > p {
                    line-height: 3.2em;
                }
            }
        }
        .kut-heading {
            font-size: 0.625em;
            line-height: 3.2em;
            color: rgba(var(--text-color), .5);
        }
        .kut-content {
            font-size: 0.75em;
            font-weight:500;
            width: 100%;
            line-height: 2em;
            color: rgba(var(--text-color), .7);
            background-color: rgb(var(--background-color));
            border-radius: 0.625em;
            min-height: 3.333em;
            max-height: 3.333em;
            padding: 0.75em 1.25em;
            outline: none;
            border: none;
        }
        .kut-link {
            text-decoration: underline;
            cursor: pointer;
            transition: opacity .3s;
            &:hover {
                opacity: .7;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .kut-component-body .kut-user-select-plan {
        .kut-select-plan-content {
            flex-wrap: wrap;
            justify-content: center;
            > div {
                width: 49%;
            }
        }
    }
}

@media screen and (max-width: 985px) {
    .kut-component-body {
        .kut-component-content {
            padding: 0em 3.625em;
            .kut-bottom-form {
                flex-direction: column;
            }
        }
    }
    
}

@media screen and (max-width: 880px) {
    .kut-component-body .kut-user-select-plan {
        .kut-select-plan-content {
            flex-wrap: wrap;
            justify-content: center;
            > div {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 790px) {
    .kut-component-body {
        .kut-component-content {
            padding: 0em 2.625em;
        }
    }
    
}

@media screen and (max-width: 760px) {
    .kut-component-body {
        .kut-component-content {
            .kut-user-avatar-div {
                flex-direction: column;
                align-items: center;
                * {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: initial !important;
                }
            }
        }
    }
    
}

@media screen and (max-width: 680px) {
    .kut-component-body {
        .kut-component-content {
            padding: 0em 1.625em;
            .kut-input-form {
                width: 100%;
            }
        }
    }
    
}

@media screen and (max-width: 576px) {
    .kut-component-body {
        .kut-component-content {
            .kut-input-form {
                width: 100%;
            }
            .kut-user-billing-plan {
                .kut-billing-current-plan {
                    .kut-billing-plan-content {
                        font-size: 0.75em;
                        .kut-billing-actions {
                            .kut-billing-action-buttons {
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .kut-user-langapp {
                .kut-langapp-config {
                    flex-direction: column;
                    .kut-langapp-config-logo {
                        .kut-langapp-config-logo-actions {
                            .kut-langapp-config-logo-action-buttons {
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .kut-user-security {
                .kut-security-level {
                    .kut-security-level-form {
                        gap: 10px;
                    }
                }
            }
        }
    }
    
}
@media screen and (max-width: 435px) {
    .kut-component-body {
        .kut-component-content {
            .kut-user-langapp {
                .kut-langapp-theme {
                    .kut-langapp-theme-content {
                        > div {
                            img {
                                min-width: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 380px) {
    .kut-component-body {
        font-size: 14px;
    }
}

@media screen and (max-width: 335px) {
    .kut-component-body {
        font-size: 10px;
    }
}
