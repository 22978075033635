@import '../../assets/sass/variables.module';

.kut-tab-view {
    .kut-content {
        position: relative;
        overflow: hidden;
        .kut-animation-item {
            position: absolute;
            top: 0;
            animation-timing-function: linear;
            animation-delay: 0s;
            opacity: 0;
            width: 100%;
        }
        .kut-animation-active {
            animation-name: TabActive;
        }
        .kut-animation-active-reverse {
            animation-name: TabActive-Reverse;
        }
        .kut-animation-inactive {
            animation-name: TabInActive;
        }
        .kut-animation-inactive-reverse {
            animation-name: TabInActive-Reverse;
        }
    }
}

@keyframes TabActive {
    0%   { opacity: 0; left: 100%; }
    100% { opacity: 1; left: 0; }
}

@keyframes TabActive-Reverse {
    0%   { opacity: 0; left: -100%; }
    100% { opacity: 1; left: 0; }
}

@keyframes TabInActive {
    0%   { opacity: 1; left: 0; }
    100% { opacity: 0; left: -100%; }
}

@keyframes TabInActive-Reverse {
    0%   { opacity: 1; left: 0; }
    100% { opacity: 0; left: 100%; }
}
