@import '../../assets/sass/variables.module';


.loadingComponent {
	position: fixed;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: white;
	background-color: rgba(0, 0, 0, 0.3);

	&.active {
		z-index: 10000;
	}

	&.inactive {
		z-index: -1;
	}
}
