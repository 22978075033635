@import '../../assets/sass/variables.module';

.kut-status-span {
	border-radius: 16px;
	display: inline-flex;
	align-items: center;
	font-size: 11px;
	padding: 2px 7px;
	.kut-status-icon {
		margin-right: 4px;
		min-width: 1em;
		min-height: 1em;
	}
	span {
		text-transform: capitalize;
	}
	&.kut-status-primary {
		background-color: $color-primary-little-light;
		color: $color-primary;
	}
	&.kut-status-secondary {
		background-color: $color-secondary-light;
		color: $color-secondary;
	}
	&.kut-status-success {
		background-color: $color-success-light;
		color: $color-success;
	}
	&.kut-status-warning {
		background-color: $color-warning-light;
		color: $color-warning;
	}
	&.kut-status-danger {
		background-color: $color-danger-light;
		color: $color-danger;
	}
	&.kut-status-info {
		background-color: $color-info-light;
		color: $color-info;
	}
}
