@import "../../assets/sass/variables.module";

.ni-body {
    width: 404px;
    height: 107px;
    background: rgba(rgb(234, 234, 234), 0.5);
    border-radius: 12.6px;
    padding: 12.3px 14.5px 17.15px 11px;
    margin-bottom: 12px;
    .ni-content {
        position: relative;
        display: flex;
        justify-content: space-between;
        .ni-content-recommend {
            display: flex;
        }
        .ni-content-icon {
            width: 30px;
            height: 30px;
            color: $color-primary;
            margin-right: 9px;
        }
        .ni-content-body {
            > p {
                margin-bottom: 0;
            }
            .ni-content-text {
                font-size: 12px;
                color: $color-primary;
                font-weight: 400;
            }
            .ni-content-title {
                font-size: 16px;
                font-weight: 700;
                color: $color-primary;      
                .ni-content-prefix {
                    color: $color-default;
                }     
            }
        }
        .ni-content-close {
			font-size: 16px;
			position: absolute;
			top: 0;
			right: 0;
			transition: color	.2s, background-color .2s;
			border-radius: 100%;
            cursor: pointer;
			&:hover {
				color: rgb(var(--content-background-color));
				background-color: rgb(var(--text-color));
			}
		}
    }
    .ni-content-footer {
        margin-top: 11.4px;
        display: flex;
        margin-left: 39px;
        .ni-footer-ok {
			font-size: 9px;
            font-weight: 500;
			text-align: center;
            margin-right: 6.4px;
		}
    }
}

@media (max-width: 450px) {
    .ni-body {
        width: 100%;
        height: auto;
        .ni-content {
            .ni-content-recommend {
                margin-top: 20px;
            }
        }
        .ni-content-footer {
            flex-direction: column;
            .ni-footer-ok {
                width: 250px;
                font-size: 12px;
                margin: 3px auto;
            }
        }
    }
}