@import './assets/sass/variables.module';

html {
	overflow: overlay;
	--background-color: #{red($color-primary-strong-light), green($color-primary-strong-light), blue($color-primary-strong-light)};
	--content-background-color: #{255, 255, 255};
	--active-item-color: #{red($color-default), green($color-default), blue($color-default)};
	--text-color: #{red($color-default), green($color-default), blue($color-default)};
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(var(--active-item-color), .3); 
	width: 10px;
	height: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(var(--active-item-color), .3); 
	border: 3px solid transparent;
	border-radius: 10px;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(var(--active-item-color), .7); 
}

.kut-app {
	font-size: 15px;
	font-family: 'Inter';
	min-height: 100vh;
	background-color: rgb(var(--background-color));
	color: rgb(var(--text-color));
	display: flex;
	flex-direction: column;
	a {
		color: $color-primary;
		transition: opacity .3s;
		&:hover {
			opacity: .7;
		}
	}
}

input {
	color: rgb(var(--text-color));
	background-color: rgb(var(--background-color));
	&::placeholder {
		color: rgba(var(--text-color), .3)
	}
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill:active {
		-webkit-text-fill-color: rgba(var(--text-color), .9) !important;
		-webkit-box-shadow: 0 0 0px 1000px rgba(var(--background-color), .9) inset !important;
	}
}

input[type=checkbox] {
	accent-color: rgb(var(--text-color));
	cursor: pointer;
}

.kut-app-is-loading {
	overflow: hidden;
}

.kut-app.kut-mode-dark {
	--background-color: #{red($color-black-light), green($color-black-light), blue($color-black-light)};
	--content-background-color: #{red($color-black-little-light), green($color-black-little-light), blue($color-black-little-light)};
	--active-item-color: #{red($color-black), green($color-black), blue($color-black)};
	--text-color: #{red($color-black-strong-light), green($color-black-strong-light), blue($color-black-strong-light)};
	caret-color: rgb(var(--text-color));
	.kut-tooltip-container {
		z-index: 10;
		white-space: pre;
		color: rgb(var(--content-background-color));
		background-color: rgb(var(--text-color));
	}
}

.kut-flex {
	display: flex !important;
}

.kut-justify-between {
	justify-content: space-between !important;
}

.kut-justify-flex-end {
	justify-content: flex-end !important;
}

.kut-items-flex-end {
	align-items: flex-end !important;
}

.kut-direction-column {
	flex-direction: column !important;
}

.kut-direction-row {
	flex-direction: row !important;
}

.kut-direction-row-reverse {
	flex-direction: row-reverse !important;
}

.kut-direction-column-reverse {
	flex-direction: column-reverse !important;
}

.kut-opacity-100 {
	opacity: 1 !important;
}

.kut-opacity-70 {
	opacity: .7 !important;
}

.kut-opacity-50 {
	opacity: .5 !important;
}

.kut-opacity-30 {
	opacity: .3 !important;
}

.kut-text-left {
	text-align: left !important;
}

.kut-text-center {
	text-align: center !important;
}

.kut-text-right {
	text-align: right !important;
}

.kut-rotation-180 {
	transform: rotateZ(180deg) !important;
}

.kut-color-primary {
	color: $color-primary !important;
}

.kut-color-secondary {
	color: $color-secondary !important;
}

.kut-color-success {
	color: $color-success !important;
}

.kut-color-warning {
	color: $color-warning !important;
}

.kut-color-danger {
	color: $color-danger !important;
}

.kut-color-info {
	color: $color-info !important;
}

.kut-color-default {
	color: $color-default !important;
}

.kut-text-underline {
	text-decoration: underline !important;
}

.kut-weight-400 {
	font-weight: 400 !important;
}

.kut-weight-500 {
	font-weight: 500 !important;
}

.kut-weight-600 {
	font-weight: 600 !important;
}

.kut-weight-700 {
	font-weight: 700 !important;
}

.kut-weight-800 {
	font-weight: 800 !important;
}

.kut-hover-opacity {
	transition: opacity .3s;
	&:hover {
		opacity: .7;
	}
}

.kut-hidden {
	visibility: hidden;
}

.kut-cursor-pointer {
	cursor: pointer !important;
}

.kut-cursor-default {
	cursor: default !important;
}

.kut-device-mobile {
	display: none !important;
}

.kut-device-desktop {
	display: flex !important;
}

@media screen and (max-width: 1345px) {
	html {
			font-size: 14px;
	}
}
@media screen and (max-width: 1250px) {
	html {
		font-size: 13px;
	}
}
@media screen and (max-width: 1200px) {
	html {
		font-size: 12px;
	}
}
@media screen and (max-width: 1060px) {
	html {
		font-size: 11px;
	}
}
@media screen and (max-width: 1035px) {
	html {
		font-size: 10px;
	}
}
@media screen and (max-width: 950px) {
	html {
		font-size: 9px;
	}
}
@media screen and (max-width: 880px) {
	html {
		font-size: 8px;
	}
}
@media screen and (max-width: 820px) {
	html {
		font-size: 7px;
	}
}
@media screen and (max-width: 670px) {
	html {
		font-size: 8px;
	}
}
@media screen and (max-width: 625px) {
	html {
		font-size: 16px;
	}
}
@media screen and (max-width: 576px) {
	html {
		font-size: 18px;
	}
	.kut-device-mobile {
		display: flex !important;
	}
	.kut-device-desktop {
		display: none !important;
	}
}

@media screen and (max-width: 500px) {
	html {
			font-size: 16px;
	}
}

@media screen and (max-width: 450px) {
	html {
			font-size: 14px;
	}
}

@media screen and (max-width: 400px) {
	html {
			font-size: 12px;
	}
}

@media screen and (max-width: 340px) {
	html {
			font-size: 10px;
	}
}

@media screen and (max-width: 290px) {
	html {
			font-size: 9px;
	}
}

@media screen and (max-width: 270px) {
	html {
			font-size: 8px;
	}
}
