@import '../../assets/sass/variables.module';
.kut-nav-container {
	display: flex;
	flex-direction: column;
	.kut-nav-bar {
		display: flex;
		justify-content: flex-start;
		font-size: 11px;

		div.kut-nav-item {
			display: block;
			cursor: pointer;
			transition: opacity .3s, font-weight .3s;
			white-space: nowrap;
			opacity: .5;
			font-weight: 400;
			padding: 0px 0.5rem;

			&:hover {
				opacity: 1;
			}

			&.active {
				font-weight: 700;
				opacity: 1;
			}

			&.active:hover {
				cursor: default;
			}

			&.active::after {
				display: block;
				content: ' ';
				height: 0.125em;
				max-height: 3px;
				margin-top: 0.625em;
				border-radius: 3px;
				background-color: $color-primary;
			}
		}
	}
	.kut-nav-arrows {
		display: flex;
		align-items: center;
		gap: 3px;
		margin-top: 10px;
		> svg {
			font-size: 1.5em;
			transition: all .3s;
			cursor: pointer;
			&:hover {
				opacity: .7;
			}
		}
	}
}
