@import '../../assets/sass/variables.module';

.kut-input-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	&.required {
		.kut-heading::after {
			color: $color-danger;
			content: ' *';
		}
	}
	
	.kut-heading {
		font-size: 0.625em;
		color: rgba(var(--text-color), .5);
	}
	
	.kut-content {
		font-size: 0.75em;
		font-weight: 500;
		width: 100%;
		line-height: 2em;
		color: rgba(var(--text-color), .7);
		background-color: rgb(var(--background-color));
		border-radius: 0.625em;
		min-height: 3.333em;
		max-height: 3.333em;
		padding: 0.75em 1.25em;
		outline: none;
		border: none;
		&:focus {
			transition: all .3s;
			border-color: rgba(var(--text-color), .3);
			box-shadow: 0 0 0 1px rgba(var(--text-color), .3);
		}
	}

	.kut-error {
		color: $color-danger;
		font-size: 0.625em;
		margin: 0px;
		line-height: 2em;
		min-width: 100%;
	}
}