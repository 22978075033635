@import '../../../assets/sass/variables.module';

.kut-component-body {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  p {
      margin-bottom: 0px;
  }

  .kut-component-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		flex: 1;
		align-items: center;
		width: 100%;
		padding: 21px;
    	.kut-component-form {
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: rgb(var(--content-background-color));
			width: 100%;
			padding: 67px 67px 20px 67px;
			border-radius: 10px;
			max-width: 477px;
			.kut-component-logo {
				img {
					width: 265px;
				}
			}
			input::placeholder {
				opacity: .5;
			}

			input {
				width: 100%;
				border: 0.15px solid $color-default-little-light;
				border-radius: 30px;
				padding: 12px 28px;
				color: rgb(var(--text-color));
				background-color: rgb(var(--content-background-color));
				margin-bottom: 20px;
				&:focus {
					outline: none;
					border: 1px solid $color-primary;
				}
			}

      .kut-component-title {
        text-align: center;
        margin-top: 22px;
        margin-bottom: 22px;
        font-size: 14.5px;
      }

			.kut-component-button {
				font-size: 16px;
				width: 100%;
				justify-content: center;
				margin-top: 22px;
				margin-bottom: 22px;
			}
    }
		.kut-signup-footer {
			position: absolute;
			color: rgb(var(--content-background-color));
			bottom: 30px;
			font-weight: 400;
			font-size: 13px;
		}
  }
}

@media screen and (max-width: 576px) {
	.kut-component-body {
		.kut-component-content {
			.kut-component-form {
				padding: 67px 40px 20px 40px;
			}
		}
	}
}
