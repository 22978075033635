@import '../../../assets/sass/variables.module';
.kut-component-body {
    font-size: 16px;
    transition: all .3s;
    color: rgb(var(--text-color));
    .kut-component-content {
        .kut-component-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            .kut-component-sort {
                display: flex;
                gap: 10px;
                &.hide {
                    visibility: hidden;
                }
                span {
                    font-size: 0.688em;
                    &.kut-component-sort-option {
                        cursor: pointer;
                        opacity: .4;
                        transition: opacity .3s;
                        font-weight: 400;
                        &.active {
                            opacity: 1;
                            font-weight: 600;
                            &:hover {
                                opacity: 1;
                            }
                            &.asc,
                            &.ASC {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                            &::after {
                                display: inline-flex;
                                margin-left: 2px;
                                content: '⮛';
                            }
                        }
                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }
            .kut-component-select-all {
                display: flex;
                align-items: center;
                gap: 10px;
                &.hide {
                    visibility: hidden;
                }
            }
            .kut-component-switch-view {
                display: flex;
            }
        }
        .kut-component-actions {
            display: flex;
            justify-content: space-between;
            gap: 1em;
            align-items: center;
            .kut-component-buttons {
                font-size: 0.9em;
                &.kut-component-button-delete {
                    opacity: 0;
                    transition: opacity .3s;
                    &.kut-active {
                        opacity: 1;
                    }
                }
                &.kut-buttons-small {
                    display: none;
                    padding: 0.75em 1em;
                }
            }
            .kut-component-search {
                max-width: 372px;
            }
        }
        img {
            width: 100%;
            border-radius: 100%;
        }
        .kut-component-table {
            display: none;
            flex-direction: column;
            background-color: rgb(var(--content-background-color));
            padding: 14px 18px;
            border-radius: 10px;
            .table-item-participant-name {
                display: flex;
                gap: 3px;
                align-items: center;
            }
            .kut-component-search {
                background-color: rgb(var(--background-color));
            }
            &.kut-component-active {
                display: flex;
            }
            a {
                &.table-item-view-devices,
                &.table-item-notion-link,
                &.table-item-view-streams {
                    color: inherit;
                }
            }
            span {
                text-decoration: underline;
                transition: opacity .3s;
                cursor: pointer;
                &:hover {
                    opacity: .5;
                }
                &.table-item-button-update {
                    color: $color-success;
                }
                &.table-item-button-delete {
                    color: $color-danger;
                }
            }
        }
        .kut-component-list-view {
            display: none;
            flex-direction: column;
            .kut-component-actions {
                padding: 14px 18px;
            }
            &.kut-component-active {
                display: flex;
            }
            .kut-component-list-item {
                display: flex;
                flex-direction: column;
                .list-item-actions {
                    display: flex;
                    gap: 3px 10px;
                    justify-content: space-between;
                    div {
                        display: flex;
                        gap: inherit;
                        flex-direction: inherit;
                        font-size: 0.656em;
                    }
                }
                .list-item-heading {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 10px;
                    .list-item-participant-name {
                        transition: all .3s;
                        &:hover {
                            cursor: pointer;
                            opacity: .5;
                        }
                    }
                }
                .list-item-name {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    white-space: nowrap;
                    gap: 10px;
                    > div {
                        display: flex;
                        flex-direction: column;
                        white-space: pre-wrap;
                        p {
                            font-size: .9em;
                            text-decoration: none;
                            overflow: hidden;
                            white-space: nowrap;
                            margin: 0;
                            &.list-item-created {
                                font-size: .6em;
                                span {
                                    text-decoration: none;
                                }
                                &.active {
                                    span {
                                        color: $color-primary;
                                    }
                                }
                                &.inactive {
                                    span {
                                        color: $color-danger;
                                    }
                                }
                            }
                        }
                    }
                }
                .list-item-user-content {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 0px;
                    font-size: 0.75em;
                    span {
                        text-decoration: none;
                    }
                    p {
                        margin: 0px;
                    }
                    > div {
                        display: flex;
                        > span {
                            min-width: 7em;
                            max-width: 7em;
                        }
                    }
                }
                a {
                    &.list-item-notion-link {
                        color: inherit;
                    }
                }
                span {
                    text-decoration: underline;
                    transition: opacity .3s;
                    cursor: pointer;
                    &:hover {
                        opacity: .5;
                    }
                    &.list-item-button-update {
                        color: $color-success;
                    }
                    &.list-item-button-delete {
                        color: $color-danger;
                    }
                }
            }
        }
    }
}

.kut-modal-form {
    .kut-title {
        font-weight: 600;
        text-align: center;
    }
    > div {
        &:not(:first-child) {
            margin-top: 10px;
        }
    }
    .kut-input-form {
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            p {
                margin: 0px;
            }
            > :first-child {
                min-width: 120px;
                max-width: 120px;
            }
        }
    }
}

.kut-create-form,
.kut-update-form {
    .input-form-title {
        font-size: 0.625em;
        line-height: 3.2em;
        color: rgba(var(--text-color), 0.5);
        white-space: nowrap;
    }
    .input-form-content {
        font-size: 0.75em;
        width: 100%;
    }
    .kut-input-form {
        text-transform: capitalize;
    }
}
.kut-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}
.kut-delete-form {
    .kut-form-delete-forever {
        display: flex;
        justify-content: flex-end;
        font-size: 13px;
        gap: 10px;
        input {
            margin-right: 0.5em;
        }
    }
}

.kut-form-delete-forever-description {
    font-size: 14px;
    color: red;
}

.action-item-list {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    > * {
        padding: 5px 8px;
        background-color: rgb(var(--content-background-color));
        transition: background-color .2s;
        &:hover {
            background-color: rgba(var(--text-color), .7);
        }
    }
    .action-button-invite {
        color: $color-primary;
    }
    .action-button-update {
        color: $color-success;
    }
    .action-button-delete {
        color: $color-danger;
    }
}

@media screen and (max-width: 930px) {
    .kut-component-body {
        font-size: 14px;
    }
}
@media screen and (max-width: 820px) {
    .kut-component-body {
        font-size: 12px;
    }
}
@media screen and (max-width: 725px) {
    .kut-component-body {
        .kut-component-content {
            .kut-component-table {
                .kut-component-actions {
                    .kut-component-buttons {
                        &.kut-buttons-large {
                            display: none;
                        }
                        &.kut-buttons-small {
                            display: initial;
                        }
                    }
                }
            }
            .kut-component-list-view {
                .kut-component-actions {
                    .kut-component-buttons {
                        &.kut-buttons-large {
                            display: none;
                        }
                        &.kut-buttons-small {
                            display: initial;
                        }
                    }
                }
                .kut-component-list-item {
                    .list-item-actions {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .kut-component-body {
        .kut-component-content {
            .kut-component-table {
                display: none;
            }
            .kut-component-list-view {
                display: flex;
                .kut-component-list-item {
                    .list-item-actions {
                        flex-direction: row;
                    }
                }
            }
        }
    }
}