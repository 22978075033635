@import '../../assets/sass/variables.module';

.search-container {
    display: flex;
    width: 100%;
    font-size: 0.9em;
    padding: 1em 2em;
    align-items: center;
    gap: 0.67em;
    background-color: rgb(var(--content-background-color));
    border-radius: 1em;
    .search-icon {
        width: 1em;
        height: 1em;
    }
    input {
        border: none;
        outline: none;
        font-weight: 400;
        font-size: 0.9em;
        width: 100%;
    }
}