@import '../../../../assets/sass/variables.module';

.kut-list-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.75em;
  .kut-item-root {
    display: flex;
    flex-direction: column;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    gap: 0.75em;
  }
}

.kut-list-item-container {
  background-color: rgb(var(--content-background-color));
  padding: 1em 1.625em;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  position: relative;
  overflow: hidden;

  .kut-list-item-collapse-bg {
    border-radius: 50%;
    background-color: #d3d3d3;
    padding: 6px;
  }

  &.removed {
    .banner {
      display: inline-block;
      position: absolute;
      font-size: 0.5em;
      top: 1.2em;
      left: -1.7em;
      transform: rotateZ(-45deg);
      color: white;
      padding: 1px 1.5em;
      background-color: $color-danger;
    }
    &.reverse {
      .banner {
        left: initial;
        right: -1.7em;
        transform: rotateZ(45deg);
      }
    }
  }
  .banner {
    display: none;
  }
  .kut-list-item-arrow {
    border: none !important;
  }
  .kut-list-item {
    display: flex;
    align-items: center;
    gap: 1.25em;
    .kut-list-item-icon {
      width: 45px;
      height: 45px;
    }
    .kut-list-item-content {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      overflow: hidden;
      white-space: nowrap;
      .kut-list-item-title {
        display: flex;
        gap: 0.5em;
        font-size: 0.625em;
        line-height: 1em;
        font-weight: 500;
        p {
          margin: 0;
        }
      }
      .kut-list-item-value {
        font-size: 1.625em;
        line-height: 1em;
        font-weight: 700;
      }
      .kut-list-item-ean-code {
        font-size: 0.5em;
        line-height: 1em;
        font-weight: 500;
        opacity: 0.5;
      }
    }
  }
}

.kut-component-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  gap: 1.875em;
  flex: 1;
  margin-left: var(--side-bar-width);
  p {
    margin: 0px;
  }
  > div {
    display: flex;
  }
  &.kut-list-item-tablet {
    .kut-component-body-content {
      margin-left: 90px;
      .kut-component-content {
        .kut-list-component {
          .kut-list-component-header {
            gap: 0.75em;
          }
          .kut-list-component-content {
            gap: 0.75em;
            .kut-list-row {
              gap: 0.75em;
            }
          }
        }
      }
    }
    .kut-component-body-footer {
      margin-left: 90px;
      gap: 0.75em;
      > div {
        gap: 0.75em;
        padding: 0.75em;
      }
    }
  }
  .kut-component-body-content {
    flex-direction: column;
    gap: 0.5em;
    margin-left: 90px;
    .device-navbar {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      > div {
        font-size: 13px;
        justify-content: space-between;
        &:nth-child(2) {
          margin-top: 0px;
          margin-left: 10px;
        }
      }
      .inside-navbar {
        display: flex;
        align-items: center;
        input {
          border-radius: 0.625em;
          outline: none;
          box-shadow: inset 0 2px 2px rgba(var(--text-color), 0.5);
          border: 1px solid rgba(var(--text-color), 0.3);
          text-align: center;
          padding: 0.5em 1em;
          width: 100%;
          box-sizing: border-box;
        }
        > span {
          margin: 0px 5px;
        }
      }
    }
    .kut-component-content {
      display: flex;
      flex-direction: row;
      gap: 1.875em;
      .kut-list-component {
        flex: 1 1;
        .kut-list-component-header {
          display: flex;
          font-size: 0.9375em;
          font-weight: 700;
          gap: 1.875em;
          margin-bottom: 0.9375em;
          > div {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            &.kut-list-align-right {
              justify-content: flex-end;
            }
            .kut-header-action {
              display: flex;
              align-items: center;
              gap: 1.5625em;
              font-size: 1.125em;
              > * {
                transition: opacity 0.3s;
                cursor: pointer;
                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
        }
        .kut-list-component-content {
          display: flex;
          gap: 1.5em;
          justify-content: center;
          .kut-list-row,
          .kut-list-row-sub {
            display: flex;
            gap: 1.25em;
            position: relative;
            justify-content: center;
            align-items: stretch;
            .kut-list-item-arrow {
              background-color: rgb(var(--content-background-color));
              border-radius: 100%;
              border: 0.75em solid rgb(var(--background-color));
              box-sizing: content-box;
              .arrow-image {
                width: 2.875em;
              }
              &.kut-list-item-absolute {
                position: absolute;
                top: calc(50% - 2.875em / 2 - 0.75em);
              }
            }
          }

          .kut-list-row-sub {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .kut-component-body-footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1.875em;
    margin-left: 90px;
    > div {
      display: flex;
      flex: 1;
      gap: 1.875em;
      padding: 1.875em;
      border-top: 1px solid rgba(var(--text-color), 0.3);
      svg {
        width: 2.5em;
        height: 2.5em;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p:first-child {
          font-size: 0.625em;
          line-height: 1em;
          font-weight: 500;
        }
        p:last-child {
          font-size: 1.625em;
          line-height: 1em;
          font-weight: 700;
        }
      }
      .kut-footer-production-content {
        overflow: hidden;
        white-space: nowrap;
        p {
          text-align: left;
          &:last-child {
            color: $color-primary;
          }
        }
      }
      .kut-footer-consumption-content {
        overflow: hidden;
        white-space: nowrap;
        p {
          text-align: right;
          &:last-child {
            color: $color-success;
          }
        }
      }
    }
    .kut-footer-production {
      flex-direction: row;
    }
    .kut-footer-consumption {
      flex-direction: row-reverse;
    }
  }
}

@media screen and (max-width: 780px) {
  .kut-component-body {
    margin-left: 30px;
    .kut-component-body-content {
      .device-navbar {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div {
          width: 100%;
          max-width: 400px;

          &:nth-child(2) {
            margin-top: 10px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .kut-component-body {
    font-size: 12px;
    .kut-component-body-content {
      .device-navbar {
        > div {
          font-size: 1em;
          overflow-x: auto;
        }
        .inside-navbar {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
