@import '../../assets/sass/variables.module';

.kut-notification-component {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	font-size: 16px;
	background-color: #0000003F;
	opacity: 0;
	z-index: -1;
	transition: opacity .3s;
	.kut-component-disabled {
		position: fixed;
		z-index: 10000;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: #D9D9D9;
		opacity: 0.5;
	}
	&.active {
		opacity: 1;
		z-index: 1001;
	}
	.kut-notification-inner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: rgb(var(--content-background-color));
		border-radius: 10px;
		padding: 40px 60px;
		box-shadow: -5px 5px 20px 8px #00000030;
		max-width: 500px;
		width: 90%;
		position: relative;
		.kut-notification-icon {
			width: 45px;
			height: auto;
		}
		.kut-notification-ai-text {
			font-size: 20px;
			color: $color-primary;
			margin: 1em 0 0 0;
			font-weight: bold;
			text-align: center;
		}
		.kut-notification-title {
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			white-space: pre-line;
		}
		.kut-notification-text {
			font-size: 18px;
			text-align: center;
			white-space: pre-line;
		}
		.kut-notification-sub-text {
			font-size: 16px;
			text-align: center;
			white-space: pre-line;
		}
		.kut-notification-title, 
		.kut-notification-text,
		.kut-notification-sub-text {
			margin-top: 0.3em;
		}
		.kut-notification-ok {
			font-size: 16px;
			margin-top: 1em;
			padding: 0.75em 3em;
			white-space: break-spaces;
			text-align: center;
		}
		.kut-notification-close {
			font-size: 16px;
			position: absolute;
			top: 10px;
			right: 10px;
			transition: color	.2s, background-color .2s;
			border-radius: 100%;
			cursor: pointer;
			&:hover {
				color: rgb(var(--content-background-color));
				background-color: rgb(var(--text-color));
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.kut-notification-component {
		.kut-notification-inner {
			width: 100%;
		}
	}
}
