@import '../../../assets/sass/variables.module';

.kut-component-body {
    font-size: 16px;
    transition: all .3s;
    color: rgb(var(--text-color));

    .kut-component-content {
        .kut-component-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .kut-component-sort {
                display: flex;
                gap: 10px;

                &.hide {
                    visibility: hidden;
                }

                span {
                    font-size: 0.688em;

                    &.kut-component-sort-option {
                        cursor: pointer;
                        opacity: .4;
                        transition: opacity .3s;
                        font-weight: 400;

                        &.active {
                            opacity: 1;
                            font-weight: 600;

                            &:hover {
                                opacity: 1;
                            }
                            &.asc,
                            &.ASC {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                            &::after {
                                display: inline-flex;
                                margin-left: 2px;
                                content: '⮛';
                            }
                        }

                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }

            .kut-component-select-all {
                display: flex;
                align-items: center;
                gap: 10px;

                &.hide {
                    visibility: hidden;
                }
            }

            .kut-component-switch-view {
                display: flex;
            }
        }

        .kut-component-actions {
            display: flex;
            justify-content: space-between;
            gap: 1em;
            align-items: center;
            .kut-component-buttons {
                font-size: 0.9em;
                &.kut-component-button-delete {
                    opacity: 0;
                    transition: opacity .3s;
                    cursor: default;
                    &.kut-active {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
                &.kut-buttons-small {
                    display: none;
                    padding: 0.75em 1em;
                }
            }
            .kut-component-search {
                max-width: 372px;
                input {
                    background-color: inherit;
                }
            }
        }

        .kut-component-table {
            display: none;
            flex-direction: column;
            background-color: rgb(var(--content-background-color));
            padding: 14px 18px;
            border-radius: 10px;

            .kut-component-search {
                background-color: rgb(var(--background-color));
            }

            .table-item-participant-name {
                display: flex;
                align-items: center;
                gap: 10px;
                img {
                    min-width: 2em;
                    min-height: 2em;
                    max-width: 2em;
                    max-height: 2em;
                    border-radius: 100%;
                }
            }

            &.kut-component-active {
                display: flex;
            }

            a {
                &.table-item-view-documents,
                &.table-item-view-devices,
                &.table-item-notion-link {
                    color: inherit;
                }
            }

            span {
                text-decoration: underline;
                transition: opacity .3s;
                cursor: pointer;

                &:hover {
                    opacity: .5;
                }

                &.table-item-button-update {
                    color: $color-success;
                }

                &.table-item-button-delete {
                    color: $color-danger;
                }
            }
        }

        .kut-component-list-view {
            display: none;
            flex-direction: column;

            .kut-component-actions {
                padding: 14px 18px;
            }

            &.kut-component-active {
                display: flex;
            }

            .kut-component-list-item {
                display: flex;
                flex-direction: column;

                .list-item-actions {
                    display: flex;
                    gap: 3px 10px;
                    justify-content: space-between;
                    div {
                        display: flex;
                        gap: inherit;
                        flex-direction: inherit;
                    }
                }

                .list-item-heading {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 10px;
                    .list-item-name {
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        white-space: nowrap;
                        img {
                            width: 2em;
                            height: 2em;
                            border-radius: 100%;
                            margin-right: 0.5em;
                        }
                        div {
                            display: flex;
                            flex-direction: column;
                            white-space: pre-wrap;
                            span {
                                font-size: .9em;
                                text-decoration: none;
                                &.list-item-joined {
                                    font-size: .6em;
                                    &.active {
                                        span {
                                            color: $color-primary;
                                        }
                                    }
                                    &.inactive {
                                        span {
                                            color: $color-danger;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }

                .list-item-views {
                    display: flex;
                    align-items: center;
                    margin: 1em 0;
                    .list-item-type {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 100%;
                        margin-right: .5em;
                        width: 2em;
                        height: 2em;
                        cursor: pointer;
                    
                        &.Prosumer {
                            background-color: $color-primary-little-light;
                            color: $color-primary;
                        }
                    
                        &.Producer {
                            background-color: $color-success-light;
                            color: $color-success;
                        }
                    
                        &.Consumer {
                            background-color: $color-warning-light;
                            color: $color-warning;
                        }
                    }
                    .list-item-views-link {
                        display: flex;
                        flex-direction: column;
                    }
                }

                p {
                    margin: 0;
                }

                a,
                span {
                    font-size: 0.656em;
                }

                a {
                    &.list-item-view-documents,
                    &.list-item-view-devices {
                        font-size: .9em;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    &.list-item-view-documents,
                    &.list-item-view-devices,
                    &.list-item-notion-link {
                        color: inherit;
                    }
                }

                span {
                    text-decoration: underline;
                    transition: opacity .3s;
                    cursor: pointer;

                    &:hover {
                        opacity: .5;
                    }

                    &.list-item-button-update {
                        color: $color-success;
                    }

                    &.list-item-button-delete {
                        color: $color-danger;
                    }
                }
            }
        }
    }
}

.action-item-list {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    > * {
        padding: 5px 8px;
        background-color: rgb(var(--content-background-color));
        transition: background-color .2s;
        &:hover {
            background-color: rgba(var(--text-color), .7);
        }
    }
    .action-button-invite {
        color: $color-primary;
    }
    .action-button-update {
        color: $color-success;
    }
    .action-button-delete {
        color: $color-danger;
    }
}

.kut-form-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    .kut-form-submit {
        display: flex;
        gap: 10px;
    }
}

.kut-modal-form {
    .kut-form-element {
        &:not(:first-child) {
            margin-top: 10px;
        }
        &:first-child {
            margin-top: 5px;
        }
        &:last-child {
            margin-bottom: 5px;
        }
    }
    .kut-body {
        overflow: auto;
    }
    .kut-input-form {
        &.kut-radio-group {
            > div > div {
                flex-wrap: wrap;
                div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        > div {
            display: flex;
            flex-direction: row;
            gap: 10px;
            * {
                margin: 0px;
            }
            > :first-child {
                min-width: 90px;
                max-width: 90px;
            }
            > p {
                font-size: 0.625em;
                color: rgba(var(--text-color), 0.5);
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.kut-delete-form {
    .kut-form-delete-forever {
        display: flex;
        justify-content: flex-end;
        font-size: 13px;

        input {
            margin-right: 0.5em;
        }
    }
}

.kut-form-delete-forever-description {
    font-size: 14px;
    color: red;
}

@media screen and (max-width: 930px) {
    .kut-component-body {
        font-size: 14px;
    }
}

@media screen and (max-width: 820px) {
    .kut-component-body {
        font-size: 12px;
    }
}

@media screen and (max-width: 725px) {
    .kut-component-body {
        .kut-component-content {
            .kut-component-table {
                .kut-component-actions {
                    .kut-component-buttons {
                        &.kut-buttons-large {
                            display: none;
                        }

                        &.kut-buttons-small {
                            display: initial;
                        }
                    }
                }
            }

            .kut-component-list-view {
                .kut-component-actions {
                    .kut-component-buttons {
                        &.kut-buttons-large {
                            display: none;
                        }

                        &.kut-buttons-small {
                            display: initial;
                        }
                    }
                }

                .kut-component-list-item {
                    .list-item-actions {
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .kut-component-body {
        .kut-component-content {
            .kut-component-table {
                display: none;
            }

            .kut-component-list-view {
                display: flex;

                .kut-component-list-item {
                    .list-item-actions {
                        flex-direction: row;
                    }
                }
            }
        }
    }
    .kut-update-form {
        .kut-form-type {
            flex-direction: column;
        }
    }
}