@import '../../../assets/sass/variables.module';

.kut-component-body {
    font-size: 16px;
    transition: all .3s;
    color: rgb(var(--text-color));
    .kut-component-content {
        .kut-component-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            .kut-component-sort {
                display: flex;
                gap: 10px;
                &.hide {
                    visibility: hidden;
                }
                span {
                    font-size: 0.688em;
                    &.kut-component-sort-option {
                        cursor: pointer;
                        opacity: .4;
                        transition: opacity .3s;
                        font-weight: 400;
                        &.active {
                            opacity: 1;
                            font-weight: 600;
                            &:hover {
                                opacity: 1;
                            }
                            &.asc,
                            &.ASC {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                            &::after {
                                display: inline-flex;
                                margin-left: 2px;
                                content: '⮛';
                            }
                        }
                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }
            .kut-component-select-all {
                display: flex;
                align-items: center;
                gap: 10px;
                &.hide {
                    visibility: hidden;
                }
            }
            .kut-component-switch-view {
                display: flex;
            }
        }
        .kut-component-actions {
            display: flex;
            justify-content: space-between;
            gap: 1em;
            align-items: center;
            .kut-component-buttons {
                font-size: 0.9em;
                &.kut-component-button-delete {
                    opacity: 0;
                    transition: opacity .3s;
                    cursor: default;
                    &.kut-active {
                        opacity: 1;
                        cursor: pointer;
                    }
                }
                &.kut-buttons-small {
                    display: none;
                    padding: 0.75em 1em;
                }
            }
            .kut-component-search {
                max-width: 372px;
            }
        }
        .kut-component-table {
            display: none;
            flex-direction: column;
            background-color: rgb(var(--content-background-color));
            padding: 14px 18px;
            border-radius: 10px;
            .kut-component-search {
                background-color: rgb(var(--background-color));
            }
            &.kut-component-active {
                display: flex;
            }
            a {
                &.table-item-community-dashboard {
                    text-decoration: underline;
                }
                &.table-item-view-participants,
                &.table-item-notion-link {
                    color: inherit;
                }
            }
            span {
                text-decoration: underline;
                transition: opacity .3s;
                cursor: pointer;
                &:hover {
                    opacity: .5;
                }
                &.table-item-button-update {
                    color: $color-success;
                }
                &.table-item-button-delete {
                    color: $color-danger;
                }
            }
        }
        .kut-component-list-view {
            display: none;
            flex-direction: column;
            .kut-component-actions {
                padding: 14px 18px;
            }
            &.kut-component-active {
                display: flex;
            }
            .kut-component-list-item {
                display: flex;
                flex-direction: column;
                .list-item-actions {
                    display: flex;
                    gap: 3px 10px;
                    justify-content: space-between;
                    div {
                        display: inherit;
                        gap: inherit;
                        flex-direction: inherit;
                    }
                }
                .list-item-heading {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 10px;
                }
                .list-item-content {
                    margin-bottom: 5px;
                    > div {
                        display: grid;
                        grid-template-columns: 70% 30%;
                        span {
                            cursor: default;
                            &:first-child {
                                text-decoration: none;
                            }
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
                p {
                    margin: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    &.list-item-name {
                        font-size: .9em;
                        display: flex;
                        transition: all .3s;
                        &:hover {
                            cursor: pointer;
                            opacity: .5;
                        }
                    }
                    &.list-item-created {
                        font-size: .6em;
                    }
                }
                a, span {
                    font-size: 0.656em;
                }
                a {
                    &.list-item-community-dashboard {
                        text-decoration: underline;
                    }
                    &.list-item-view-participants {
                        font-size: .9em;
                        margin: 1em 0;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    &.list-item-view-participants,
                    &.list-item-notion-link {
                        color: inherit;
                    }
                }
                span {
                    text-decoration: underline;
                    transition: opacity .3s;
                    cursor: pointer;
                    &:hover {
                        opacity: .5;
                    }
                    &.list-item-button-update {
                        color: $color-success;
                    }
                    &.list-item-button-delete {
                        color: $color-danger;
                    }
                }
            }
        }
    }
}

.kut-modal-form {
    .kut-title {
        font-weight: 600;
        text-align: center;
    }
    .kut-modal-viewport {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > div {
            &:not(:first-child) {
                margin-top: 10px;
            }
            > div {
                &:not(:first-child) {
                    margin-top: 10px;
                }
            }
        }
    }
    .kut-input-form {
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            * {
                margin: 0px;
            }
            > :first-child {
                min-width: 150px;
                max-width: 150px;
            }
        }
    }
    .kut-modal-column {
        width: 49%;
    }
}

.kut-form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.kut-delete-form {
    .kut-form-delete-forever {
        display: flex;
        justify-content: flex-end;
        font-size: 13px;
        input {
            margin-right: 0.5em;
        }
    }
}

@media screen and (max-width: 930px) {
    .kut-component-body, 
    .kut-modal-form {
        font-size: 14px;
    }
    .kut-modal-form {
        .kut-modal-column {
            width: 48%;
        }
    }
}

@media screen and (max-width: 820px) {
    .kut-component-body, 
    .kut-modal-form {
        font-size: 12px;
    }
}

@media screen and (max-width: 725px) {
    .kut-component-body {
        .kut-component-content {
            .kut-component-table {
                .kut-component-actions {
                    .kut-component-buttons {
                        &.kut-buttons-large {
                            display: none;
                        }
                        &.kut-buttons-small {
                            display: initial;
                        }
                    }
                }
            }
            .kut-component-list-view {
                .kut-component-actions {
                    .kut-component-buttons {
                        &.kut-buttons-large {
                            display: none;
                        }
                        &.kut-buttons-small {
                            display: initial;
                        }
                    }
                }
                .kut-component-list-item {
                    .list-item-actions {
                        flex-direction: column;
                    }
                }
            }
        }
    }
    .kut-modal-form {
        .kut-modal-column {
            width: 100%;
        }
    }
}

@media screen and (max-width: 576px) {
    .kut-component-body, 
    .kut-modal-form {
        font-size: 14px;
    }
    .kut-component-body {
        .kut-component-content {
            .kut-component-table {
                display: none;
            }
            .kut-component-list-view {
                display: flex;
                .kut-component-list-item {
                    .list-item-actions {
                        flex-direction: row;
                    }
                }
            }
        }
    }
}
