@import '../../assets/sass/variables.module';

.kut-component-body {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 0.75em;
    border-radius: 0.625em 0.625em 0em 0em;

    p {
        margin: 0em;
    }
    .kut-component-header {
        display: flex;
        justify-content: center;
        padding: 2.8125em;
        color: rgb(var(--content-background-color));
        background: center / cover no-repeat url('../../assets/images/background2.png');
        border-radius: 0.625em;
        text-align: center;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            svg {
                font-size: 1.5625em;
            }
            p.kut-component-header-title {
                margin: 0.25em 0em;
                font-size: 2em;
                font-weight: 600;
            }
            > div {
                background-color: rgb(var(--content-background-color));
                color: $color-default;
                width: 100%;
                padding: 0.875em;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.625em;
                input {
                    border: 0em;
                    margin-left: 0.625em;
                    border-bottom: 0.0625em solid rgba(var(--text-color), .2);
                    transition: border-bottom .3s;
                    text-align: center;
                    font-size: 0.8125em;
                    font-weight: 400;
                    background-color: rgb(var(--content-background-color));
                    &::placeholder {
                        color: $color-default;
                        opacity: .5;
                    }
                }
                input:focus {
                    outline: none;
                    border-bottom: 0.0625em solid transparent;
                }
                svg {
                font-size: 1.125em;
                opacity: .5;
                }
            }
            p.kut-component-header-description {
                font-size: 0.8125em;
                font-weight: 400;
                line-height: 3.5em;
                a {
                    color: rgb(var(--content-background-color));
                    text-decoration: underline;
                }
            }
        }
    }
    .kut-component-content {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        > div {
            display: flex;
            justify-content: center;
            width: 32%;
            padding: 2.5em 8%;
            background-color: rgb(var(--content-background-color));
            margin-top: 1em;
            border-radius: 0.625em;
            > div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                img {
                    width: 100%;
                }
                p {
                    text-align: center;
                }
                .kut-content-item-title {
                    font-size: 1.375em;
                    font-weight: 700;
                    margin: 0.5em 0em;
                }
                .kut-content-item-description {
                    font-size: 0.8125em;
                    font-weight: 500;
                    line-height: 1.692em;
                    opacity: .7;
                    margin-bottom: 1.5em;
                }
                a {
                    font-size: 0.8125em;
                    font-weight: 500;
                    line-height: 1.692em;
                    color: $color-primary;
                    text-decoration: underline;
                }
                > * {
                    text-align: center;
                }
            }
        }
    }
}

@media screen and (max-width: 1380px) {
    .kut-component-body {
        .kut-component-content {
            > div {
                padding: 2.5em 6%;
            }
        }
    }
}

@media screen and (max-width: 1255px) {
    .kut-component-body {
        .kut-component-content {
            > div {
                padding: 2.5em 5%;
            }
        }
    }
}

@media screen and (max-width: 1155px) {
    .kut-component-body {
        .kut-component-content {
            > div {
                padding: 2.5em 4%;
            }
        }
    }
}

@media screen and (max-width: 1065px) {
    .kut-component-body {
        .kut-component-content {
            > div {
                padding: 2.5em 3%;
            }
        }
    }
}

@media screen and (max-width: 990px) {
    .kut-component-body {
        .kut-component-content {
            flex-wrap: wrap;
            justify-content: center;
            > div {
                width: 49%;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .kut-component-body {
        .kut-component-content {
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            > div {
                width: 100%;
                padding: 2.5em 20%;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .kut-component-body {
        position: relative;
        .kut-component-header {
            border-radius: 0px;
            margin: -26px;
            padding-bottom: 6em;
        }
        .kut-component-content {
            margin-top: -80px;
        }
    }
}

@media screen and (max-width: 450px) {
    .kut-component-body {
        .kut-component-content {
            > div {
                width: 100%;
                padding: 2.5em 15%;
            }
        }
    }
}

@media screen and (max-width: 300px) {
    .kut-component-body {
        .kut-component-content {
            > div {
                width: 100%;
                padding: 2.5em 10%;
            }
        }
    }
}
