@import '../../assets/sass/variables.module';


.kut-phone-input-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	.kut-phone-input-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		> div {
			font-size: inherit;
			ul {
				background-color: rgb(var(--background-color));
			}
			li {
				background-color: rgb(var(--background-color)) !important;
				&:first-child {
					display: flex;
					align-items: center;
					gap: 5px;
					input {
						min-height: initial;
						max-height: initial;
						padding: 0.75em 1.25em;
						border-color: rgb(var(--content-background-color));
						margin: 0;
						&:hover {
							border-color: rgba(var(--content-background-color), .5);
						}
					}
				}
			}
			span {
				font-size: 0.75em;
			}
			> input {
				font-size: 0.75em;
				font-weight: 500;
				width: 100%;
				line-height: 2em;
				color: rgba(var(--text-color), 0.7);
				background-color: rgb(var(--background-color));
				border-radius: 0.625em;
				min-height: 3.333em;
				max-height: 3.333em;
				padding: 0.75em 1.25em 0.75em 3.85em;
				outline: none;
				border: none;
				&:focus {
					transition: all .3s;
					border-color: rgba(var(--text-color), .3);
					box-shadow: 0 0 0 1px rgba(var(--text-color), .3);
				}
			}
		}
	}

	&.required {
		.kut-heading::after {
			color: $color-danger;
			content: ' *';
		}
	}
	
	.kut-heading {
		font-size: 0.625em;
		line-height: 3.2em;
		color: rgba(var(--text-color), .5);
		white-space: nowrap;
	}
	
	.kut-error {
		color: $color-danger;
		font-size: 0.625em;
		margin: 0px;
		line-height: 2em;
		min-width: 100%;
	}
}
