@import '../../../assets/sass/variables.module';

.kut-intro-body {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .kut-intro-logo {
        width: 60%;
    }
    .kut-intro-button {
        margin-top: 100px;
        font-weight: 400;
        font-size: 16px;
        max-width: 308px;
        width: 90%;
        color: #FFFFFF;
    }
    .kut-intro-footer {
        position: absolute;
        bottom: 30px;;
        font-weight: 400;
        font-size: 13px;
        text-align: center;
        color: #FFFFFF;
    }
}
