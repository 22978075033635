@import "../../assets/sass/variables.module";

.main-body-header-container {
	font-weight: 600;
	font-size: 16px;
	padding: 3.0625em 1.5em 0.8em 2em;
	display: flex;
	flex-direction: column;
	background-color: rgb(var(--content-background-color));
	transition: all .3s;
	p {
		margin: 0;
	}
	&.kut-device-mobile {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 2;
		&.fake {
			position: relative;
			visibility: hidden;
			z-index: 0;
		}
		.main-body-header-location {
			font-size: 0.8125em;
			justify-content: center;
			.main-body-header-location-temperature {
				svg {
					font-size: 1.5em;
					margin-right: 0.4375em;
				}
			}
		}
	}
	.header-title-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		font-size: 1.325em;
		div {
			height: 2em;
			display: flex;
			align-items: center;
			span {
				margin: 0px 5px;
			}
		}
	}
	.main-body-header-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.user-info-wrapper {
			display: flex;
			gap: 0.875em;
			.user-info-detail-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				> p {
					margin: 0;
				}
				.user-info-name-label {
					font-size: 1em;
					font-weight: 600;
					text-align: right;
					cursor: pointer;
				}
				.user-info-group-name-label {
					font-size: 0.75em;
					font-weight: 400;
					text-align: right;
					cursor: pointer;
					text-transform: capitalize;

					span {
						display: flex;
						gap: 8px;
						align-items: center;
						padding-left: 0.5em;
						font-weight: 600;
						font-size: 12.6px;
						color: $color-primary;
					}
				}
			}
			.user-info-avatar-wrapper {
				position: relative;
				.user-info-avatar {
					cursor: pointer;
					border-radius: 100%;
				}
				.is-online-flag-section {
					position: absolute;
					background-color: $color-success;
					width: 0.75em;
					height: 0.75em;
					z-index: 9;
					border-radius: 0.75em;
					border: 0.125em solid white;
					right: 0;
					bottom: 0;
				}
				.is-online-flag-section.deactive {
					background-color: $color-default;
				}
			}
			.dropdown-button-wrapper {
				display: flex;
				flex-direction: column;
				font-size: 0.75em;
				> svg {
					cursor: pointer;
					&:hover {
						color: $color-success;
					}
				}
			}
		}
	}
	.main-body-header-location {
		display: flex;
		font-size: 0.9375em;
		font-weight: 400;
		> * {
			&:not(:first-child) {
				padding-left: 1.125em;
			}
			&:not(:last-child) {
				padding-right: 1.125em;
				border-right: 0.0625em solid rgba(var(--text-color), .3);  
			}
		}
		.main-body-header-location-date {
			font-weight: 400;
		}
		.main-body-header-location-temperature {
			font-weight: 600;
			svg {
				color: $color-warning;
				font-size: 1.5em;
				margin-right: 0.625em;
			}
		}
		.main-body-header-location-cityname {
			cursor: pointer;
			border-bottom: 0.0625em solid transparent;
			transition: opacity .3s, border-bottom .3s;
			&:hover {
				opacity: .7;
				border-bottom: 0.0625em solid rgb(var(--text-color));      
			}
			svg {
				font-size: 0.5em;
				margin-left: 0.5em;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	.main-body-header-container {
		font-size: 14px;
	}
}

@media screen and (max-width: 576px) {
	.main-body-header-container {
		font-size: 16px;
	}
}

@media screen and (max-width: 400px) {
	.main-body-header-container {
		font-size: 14px;
	}
}

@media screen and (max-width: 350px) {
	.main-body-header-container {
		font-size: 12px;
	}
}

@media screen and (max-width: 300px) {
	.main-body-header-container {
		font-size: 10px;
	}
}
