@import "../../assets/sass/variables.module";

.main-body-footer-container {
	position: fixed;
	display: flex;
	justify-content: space-between;
	bottom: 0px;
	width: 100%;
	background-color: rgb(var(--content-background-color));
	padding: 25px 60px;
	z-index: 1;
	&.fake {
		position: static;
		z-index: 0;
		visibility: hidden;
	}
	.kut-footer-item {
		font-size: 25px;
		color: rgb(var(--color-text));
		&.active {
			color: $color-primary;
		}
	}
}
