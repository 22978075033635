@import '../../assets/sass/variables.module';


.kut-button-component {
	font-size: 0.688em;
	border-radius: 10em;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.75em 2.25em;
	color: rgb(var(--content-background-color));
	border: 1px solid transparent;
	transition: all .3s;
	cursor: pointer;
	white-space: nowrap;
	&:hover {
		background-color: rgb(var(--content-background-color));
		opacity: .7;
	}
	&.kut-button-default {
		background-color: rgb(var(--text-color));
		--outline-text-color: rgb(var(--text-color));
	}
	&.kut-button-primary {
		background-color: $color-primary;
		--outline-text-color: rgb(#{red($color-primary), green($color-primary), blue($color-primary)});
	}
	&.kut-button-secondary {
		background-color: $color-secondary;
		--outline-text-color: rgb(#{red($color-secondary), green($color-secondary), blue($color-secondary)});
	}
	&.kut-button-success {
		background-color: $color-success;
		--outline-text-color: rgb(#{red($color-success), green($color-success), blue($color-success)});
	}
	&.kut-button-warning {
		background-color: $color-warning;
		--outline-text-color: rgb(#{red($color-warning), green($color-warning), blue($color-warning)});
	}
	&.kut-button-danger {
		background-color: $color-danger;
		--outline-text-color: rgb(#{red($color-danger), green($color-danger), blue($color-danger)});
	}
	&.kut-button-info {
		background-color: $color-info;
		--outline-text-color: rgb(#{red($color-info), green($color-info), blue($color-info)});
	}
	&.kut-button-outline {
		color: var(--outline-text-color);
		background-color: rgb(var(--content-background-color));
		border-color: var(--outline-text-color);
		&:hover {
			background-color: var(--outline-text-color);
			color: rgb(var(--content-background-color));
			opacity: .5;
		}
	}
}
