@import '../../../../assets/sass/variables.module';

.kut-component-body {
  font-size: 16px;
  transition: all 0.3s;
  margin-left: var(--side-bar-width);

  .kut-component-content {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin-left: 90px;
    .energy-select-tag {
      display: flex;
      justify-content: center;
      > div {
        display: flex;
        justify-content: center;
        max-width: 100%;
        padding: 0.25em;
        background-color: rgba(var(--content-background-color), 0.5);
        border-radius: 100px;
        gap: 1em;
        > span {
          padding: 1em 3.375em;
          color: rgba(var(--text-color), 0.5);
          cursor: pointer;
          background-color: transparent;
          border-radius: 100px;
          transition: all 0.3s;
          gap: 0.3em;
          display: flex;
          align-items: center;
          font-weight: 700;
          &.active {
            cursor: default;
            background-color: rgb(var(--content-background-color));
            box-shadow: 1.063em 2.25em 1em rgba(37, 37, 55, 0.01),
              0.625em 1.25em 0.875em rgba(37, 37, 55, 0.03),
              0.25em 0.5625em 0.625em rgba(37, 37, 55, 0.04),
              0.0625em 0.125em 0.375em rgba(37, 37, 55, 0.05),
              0em 0em 0em rgba(37, 37, 55, 0.05);
            &.energy-select-tag-consumption {
              color: $color-success;
            }
            &.energy-select-tag-production {
              color: $color-warning;
            }
          }
        }
      }
    }
    .energy-action-bars {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 2em;
      .energy-navbar {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 400px;
        > div {
          font-size: 13px;
          justify-content: space-between;
        }
        .inside-navbar {
          display: flex;
          align-items: center;
          input {
            border-radius: 0.625em;
            outline: none;
            box-shadow: inset 0 2px 2px rgba(var(--text-color), 0.5);
            border: 1px solid rgba(var(--text-color), 0.3);
            text-align: center;
            padding: 0.5em 1em;
            width: 100%;
            box-sizing: border-box;
          }
          > span {
            margin: 0px 5px;
          }
        }
      }
      .energy-export {
        display: flex;
        gap: 0.25em;
        font-size: 0.875em;
        position: absolute;
        right: 0px;
        bottom: -2em;
        white-space: nowrap;
      }
    }
    .energy-result {
      display: flex;
      gap: 1.375em;
      overflow: auto;
      > div {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        gap: 1.875em;
        justify-content: space-between;
        padding: 1em;
        background-color: rgb(var(--content-background-color));
        border-radius: 10px;
        .energy-result-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 0.875em;
            font-weight: 700;
            text-transform: capitalize;
          }
          svg {
            font-size: 1.125em;
          }
        }
        .energy-result-value {
          display: flex;
          align-items: baseline;
          gap: 0.25em;
          span {
            &:first-child {
              font-size: 3em;
              font-weight: 600;
            }
            &:last-child {
              font-size: 0.875em;
              font-weight: 700;
            }
          }
        }
      }
    }
    .energy-chart {
      height: 380px;
      display: block;
      position: relative;
      background-color: rgb(var(--content-background-color));
      border-radius: 10px;
      padding: 1.25rem;
      font-size: 12px;
    }
  }
}

.energy-tooltip-item {
  white-space: wrap;
  width: 75vw;
  max-width: 500px;
  margin: 0;
  padding: 1rem 2rem;
}

@media screen and (max-width: 725px) {
  .kut-component-body {
    .kut-component-content {
      font-size: 14px;
      margin-left: 60px;
    }
  }
}

@media screen and (max-width: 625px) {
  .kut-component-body {
    .kut-component-content {
      margin-left: 50px;
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 430px) {
  .kut-component-body {
    .kut-component-content {
      margin-left: 40px;
      .energy-select-tag {
        > div {
          flex-direction: column;
          background-color: inherit;
        }
      }
      .energy-chart {
        height: 300px;
      }
      .energy-action-bars {
        .energy-navbar {
          > div {
            font-size: 1em;
            overflow-x: auto;
          }
          .inside-navbar {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
}
