@import "../../assets/sass/variables.module";

.action-center-arrow {
    position: absolute;
    width: 15px;
    height: 25px;
    top: 64.23px;
    right: 500px;
    border-radius: 6.6px 0px 0px 6.6px;
    background-color: $color-white;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
}
.action-center-body {
    width: 500px;
    position: fixed;
    right: -500px;
    top: 0;
    bottom: 0;
    z-index: 11111;
    background-color: $color-white;
    transition: transform 0.3s ease-in-out;
    padding: 70px 45px 20px 50px;
    .action-center-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 38px;
        .action-center-alarm {
            width: 22.4px;
            height: 22.4px;
            border: 2.44px;
        }
        .action-center-user {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .action-center-header-title {
                > p {
                    margin-bottom: 0;
                }
                .action-center-header-name {
                    font-size: 21px;
                    font-weight: 600;
                    text-align: right;
                    color: $color-default;;
                }
                .action-center-header-role {
                    font-weight: 500;
                    font-size: 16px;
                    text-align: right;
                    color: #74859680;
                }
            }
            .action-center-user-wrapper {
                position: relative;
                margin-left: 20px;
                .user-info-avatar {
                    cursor: pointer;
                    border-radius: 100%;
                }
                .is-online-flag-section {
                    position: absolute;
                    background-color: $color-success;
                    width: 1em;
                    height: 1em;
                    z-index: 9;
                    border-radius: 1em;
                    border: 0.125em solid $color-white;
                    right: 0;
                    bottom: 0;
                }
                .is-online-flag-section.deactive {
                    background-color: $color-default;
                }
            }
            .dropdown-button-wrapper {
                display: flex;
                flex-direction: column;
                font-size: 0.75em;
                margin-left: 17px;
                > svg {
                    cursor: pointer;
                    &:hover {
                        color: $color-success;
                    }
                }
            }
        }
    }
    .action-center-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        .today-date {
            font-size: 12px;
            font-weight: 400;
            color: $color-default;
            margin-bottom: 15px;
        }
        .action-center-content-button {
            display: flex;
            justify-content: center;
            margin-top: 4.7px;
        }
        .action-center-button {
            background-color: #EAEAEA;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: $color-default;
        }
    }
    .action-center-clear {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .action-center-clear-button {
            background-color: rgb(244, 230, 226);
            color: $color-danger;
            font-size: 12px;
            .trash-image {
                width: 13px;
                height: 13px;
                margin-right: 2px;   
                margin-bottom: 2px;
            }
        }
    }
}
.action-center-hidden {
    transform: translateX(500px);
}

.action-center-show {
    transform: translateX(-500px);
}

@media (max-width: 550px) {
    .action-center-body {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        overflow: auto;
    }
    .action-center-arrow {
        left: 10px;
        top: 30px;
    }
}