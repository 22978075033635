@import '../../../../assets/sass/variables.module';

.kut-component-body {
  margin-left: var(--side-bar-width);
  color: $color-default;
  p {
    margin: 0;
  }
  a {
    &.table-item-view-devices {
      color: inherit;
    }
  }
  .kut-component-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 32px;
    margin-left: 90px;
    .kut-component-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;
      .kut-component-sort {
        display: flex;
        gap: 10px;
        span {
          font-size: 0.688em;
          &.kut-component-sort-option {
            cursor: pointer;
            opacity: 0.4;
            transition: opacity 0.3s;
            font-weight: 400;
            &.active {
              opacity: 1;
              font-weight: 600;
              &:hover {
                opacity: 1;
              }
              &.asc,
              &.ASC {
                &::after {
                  transform: rotate(180deg);
                }
              }
              &::after {
                display: inline-flex;
                margin-left: 2px;
                content: '⮛';
              }
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
      .kut-component-select-all {
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .kut-component-switch-view {
        display: flex;
      }
    }
    .kut-component-table-view {
      width: 100%;
    }
    .kut-component-list-view {
      width: 100%;
    }
    .kut-component-actions {
      display: flex;
      justify-content: space-between;
      gap: 1em;
      align-items: center;
      margin-bottom: 10px;
      .kut-component-buttons {
        font-size: 0.9em;
        &.kut-component-button-delete {
          opacity: 0;
          transition: opacity 0.3s;
          cursor: default;
          &.kut-active {
            opacity: 1;
            cursor: pointer;
          }
        }
        &.kut-buttons-small {
          display: none;
          padding: 0.75em 1em;
        }
      }
      .kut-component-search {
        max-width: 372px;
        background-color: rgb(var(--content-background-color));
        input {
          background-color: rgb(var(--content-background-color));
        }
      }
    }
    .kut-table-request {
      display: flex;
      justify-content: space-between;
    }
    .kut-table-title {
      font-size: 11px;
      font-weight: 400;
    }
    .kut-table-switch {
      margin-left: 6px;
    }
    .kut-list-container {
      .kut-list-item {
        &::after {
          display: block;
          width: 100%;
          border-bottom: 1px solid rgba(var(--text-color), 0.2);
        }
        .kut-item-header {
          display: flex;
          justify-content: space-between;
          padding-top: 18px;
          padding-bottom: 16px;
          align-items: stretch;
        }
        .kut-item-name {
          display: flex;
          .kut-item-avatar {
            display: inline-block;
            min-width: 47px;
            max-width: 47px;
            height: 47px;
            background-size: cover;
            background-position: center;
            border-radius: 50%;
          }
          .kut-item-text {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:first-child {
              font-size: 15px;
              font-weight: 600;
            }
            &:last-child {
              font-size: 11px;
              opacity: 0.5;
            }
          }
        }
        .kut-item-actions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
        > div {
          display: flex;
          align-items: center;
          min-height: 38px;
          &:not(:last-child) {
            border-bottom: 1px solid rgba(var(--text-color), 0.2);
          }
          .kut-item-title {
            font-size: 11px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 30%;
          }
          .kut-item-content {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            width: 70%;
          }
        }
      }
    }
    .kut-table {
      display: flex;
      flex-direction: column;
      width: 100%;
      .kut-table-name {
        display: flex;
        align-items: center;
        cursor: pointer;
        .kut-table-avatar {
          display: inline-block;
          min-width: 47px;
          max-width: 47px;
          height: 47px;
          background-size: cover;
          background-position: center;
          border-radius: 50%;
        }
        span {
          overflow: hidden;
          // white-space: nowrap;
          text-overflow: ellipsis;
          font-weight: 600;
        }
      }
      .kut-table-ean-status {
        color: $color-primary;
        background-color: $color-primary-little-light;
        padding: 6px 9px 6px 6px;
        border-radius: 20px;
        font-size: 13px;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        > svg {
          margin-right: 0.25em;
        }
      }
      .kut-table-request-status {
        color: $color-warning;
      }
      .kut-table-main {
        padding: 0px 20px;
        border-radius: 10px;
        background-color: rgb(var(--content-background-color));
        margin-bottom: 16px;
        margin-top: 10px;
      }
    }
  }
}

.kut-form-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
  .kut-form-submit {
    display: flex;
    gap: 10px;
  }
}

.kut-modal-form {
  .kut-form-element {
    &:not(:first-child) {
      margin-top: 10px;
    }
    &:first-child {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 5px;
    }
  }
  .kut-body {
    overflow: auto;
  }
  .kut-input-form {
    &.kut-radio-group {
      > div > div {
        flex-wrap: wrap;
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    > div {
      display: flex;
      flex-direction: row;
      gap: 10px;
      * {
        margin: 0px;
      }
      > :first-child {
        min-width: 90px;
        max-width: 90px;
      }
      > p {
        font-size: 0.625em;
        color: rgba(var(--text-color), 0.5);
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.action-item-list {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  > * {
    padding: 5px 8px;
    background-color: rgb(var(--content-background-color));
    transition: background-color 0.2s;
    &:hover {
      background-color: rgba(var(--text-color), 0.7);
    }
  }
  .action-button-invite {
    color: $color-primary;
  }
  .action-button-update {
    color: $color-success;
  }
  .action-button-delete {
    color: $color-danger;
  }
}

@media screen and (max-width: 725px) {
  .kut-component-body {
    .kut-component-content {
      margin-left: 60px;
      .kut-component-actions {
        .kut-component-buttons {
          &.kut-buttons-large {
            display: none;
          }
          &.kut-buttons-small {
            display: initial;
          }
        }
      }
      .kut-component-actions {
        .kut-component-buttons {
          &.kut-buttons-large {
            display: none;
          }
          &.kut-buttons-small {
            display: initial;
          }
        }
      }
    }
  }
}
