@import '../../../../assets/sass/variables.module';

.kut-component-body {
  color: rgb(var(--text-color));
  position: relative;
  margin-left: var(--side-bar-width);
  .kut-list-component {
    margin-left: 90px;
    .kut-list-component-header {
      font-size: 11px;
      font-weight: 400;
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .kut-switch-view {
        display: flex;
        align-items: center;
        font-size: 11px;
        margin-right: 3px;
        span {
          margin-right: 5px;
        }
        svg {
          font-size: 13px;
          margin-left: 15px;
          opacity: 0.3;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }
        .kut-active {
          opacity: 1;
          transition: opacity 0.3s;
          &:hover {
            opacity: 1;
          }
        }
      }
      .kut-action-group {
        display: flex;
        gap: 4em;
      }
      .kut-download-all {
        display: flex;
        align-items: center;
        font-size: 11px;
        margin-right: 3px;
        cursor: pointer;
        transition: opacity 0.3s;
        &:hover {
          opacity: 0.7;
        }
        span {
          margin-left: 5px;
        }
        svg {
          font-size: 13px;
        }
      }
    }
    .kut-component-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .kut-component-buttons {
        font-size: 0.9em;
        &.kut-component-button-delete {
          opacity: 0;
          transition: opacity 0.3s;
          cursor: default;
          &.kut-active {
            opacity: 1;
            cursor: pointer;
          }
        }
        &.kut-buttons-small {
          display: none;
          padding: 0.75em 1em;
        }
      }
      .kut-component-search {
        max-width: 372px;
      }
    }
    .kut-component-select-all {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      &.hide {
        visibility: hidden;
      }
    }
    .kut-list-component-content {
      .kut-list-component-table {
        background-color: rgb(var(--content-background-color));
        padding: 14px 18px;
        border-radius: 10px;
        flex: 1 1;
        margin-bottom: 16px;
        width: 100%;
        border-radius: 10px;
        .kut-list-item-heading {
          margin-bottom: 0px;
        }
      }
      > div {
        .kut-list-item-heading {
          margin-bottom: 10px;
          display: flex;
          overflow: visible;
          position: relative;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          &.kut-new-data {
            &::before {
              position: absolute;
              left: -8px;
              content: ' ';
              width: 2.75px;
              height: 2.75px;
              border-radius: 50%;
              background-color: $color-warning;
            }
            .kut-list-item-name {
              &::after {
                content: 'NEW';
                font-size: 8px;
                font-weight: 400;
                color: $color-warning;
                margin-left: 10px;
              }
            }
          }
          .kut-list-item-name {
            display: flex;
            align-items: center;
            position: relative;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            padding-right: 10px;
            > span {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          svg {
            font-size: 15px;
            min-width: 1em;
            max-width: 1em;
            margin-right: 3px;
          }
        }
        .kut-list-item-document-id {
          font-weight: 600;
        }
        .kut-list-item-actions {
          display: inline-block;
          cursor: pointer;
          transition: opacity 0.3s;
          font-size: 11px;
          overflow: hidden;
          color: rgb(var(--text-color));
          &:hover {
            opacity: 0.7;
          }
          svg {
            font-size: 18px;
          }
        }
        .kut-list-item-action {
          cursor: pointer;
          &:hover {
            opacity: 0.5;
          }
        }
        > .kut-list-item {
          background-color: rgb(var(--content-background-color));
          padding: 14px 16px;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-bottom: 30px;
          transition: all 0.3s;
          .kut-list-item-content {
            margin-bottom: 10px;
            justify-content: space-between;
            gap: 3px;
            display: flex;
            align-items: center;
            position: relative;
          }
          .kut-list-item-event {
            display: flex;
            gap: 10px;
          }
        }
      }
    }
  }
}

.kut-modal-form {
  .kut-title {
    font-weight: 600;
    text-align: center;
  }
  > div {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  .kut-input-form {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      * {
        margin: 0px;
      }
      > :first-child {
        min-width: 120px;
        max-width: 120px;
      }
    }
  }
}

.kut-create-form,
.kut-update-form {
  .kut-select-inner-item {
    display: flex;
    align-items: center;
    gap: 10px;
    .kut-select-inner-item-avatar {
      width: 35px;
      height: 35px;
      background-size: cover;
      background-position: center;
      border-radius: 100%;
    }
  }
  .input-form-title {
    font-size: 0.625em;
    line-height: 3.2em;
    color: rgba(var(--text-color), 0.5);
    white-space: nowrap;
    text-transform: capitalize;
  }
  .input-form-content {
    font-size: 0.75em;
    width: 100%;
  }
  .kut-form-document-item {
    display: flex;
    flex-direction: column;
    > p {
      color: #c83b0f;
      font-size: 0.625em;
      margin: 0px;
      line-height: 2em;
      min-width: 100%;
    }
    .input-form-title::after {
      color: #c83b0f;
      content: ' *';
    }
    .input-form-content {
      display: flex;
      color: rgba(var(--text-color), 0.2);
      flex-direction: column;
      align-items: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: rgba(var(--text-color), 0.2);
      border-style: dashed;
      outline: none;
      transition: color 0.24s ease-in-out, border 0.24s ease-in-out;
      &:focus {
        border-color: rgba(var(--text-color), 1);
        color: rgba(var(--text-color), 1);
      }
      cursor: pointer;
      p {
        margin: 0;
        text-align: center;
        overflow-wrap: anywhere;
      }
    }
  }
  .kut-form-document-status {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
  }
  .kut-form-document-switch {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
.kut-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}
.kut-delete-form {
  .kut-form-delete-forever {
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    gap: 10px;
    input {
      margin-right: 0.5em;
    }
  }
}

.kut-form-delete-forever-description {
  font-size: 14px;
  color: red;
}

.action-item-list {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  > * {
    padding: 5px 8px;
    background-color: rgb(var(--content-background-color));
    transition: background-color 0.2s;
    &:hover {
      background-color: rgba(var(--text-color), 0.7);
    }
  }
  .action-button-update {
    color: $color-success;
  }
  .action-button-delete {
    color: $color-danger;
  }
}

@media screen and (max-width: 768px) {
  .kut-component-body {
    .kut-list-component {
      margin-left: 60px;
      .kut-component-actions {
        .kut-component-buttons {
          &.kut-buttons-large {
            display: none;
          }
          &.kut-buttons-small {
            display: initial;
          }
        }
      }
    }
  }
}
