@import '../../../assets/sass/variables.module';

.kut-component-body {
  font-size: 16px;
  transition: all 0.3s;

  .kut-component-content {
    display: flex;
    flex-direction: column;
    gap: 1.25em;
    p {
      margin: 0em;
    }
    .community-navbar {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      > div {
        font-size: 13px;
        justify-content: space-between;
        &:nth-child(2) {
          margin-top: 0px;
          margin-left: 10px;
        }
      }
      .inside-navbar {
        display: flex;
        align-items: center;
        input {
          border-radius: 0.625em;
          outline: none;
          box-shadow: inset 0 2px 2px rgba(var(--text-color), 0.5);
          border: 1px solid rgba(var(--text-color), 0.3);
          text-align: center;
          padding: 0.5em 1em;
          width: 100%;
          box-sizing: border-box;
        }
        > span {
          margin: 0px 5px;
        }
      }
    }
    .community-detailed-info {
      display: flex;
      gap: 1.25em;
    }
    .community-self-sufficiency {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      padding: 1.125em;
      background-color: rgb(var(--content-background-color));
      border-radius: 10px;
      min-width: 220px;
      .community-self-sufficiency-header {
        display: flex;
        flex: 1 1;
        font-weight: 700;
        font-size: 0.875em;
        justify-content: space-between;
      }
      .community-self-sufficiency-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .community-circular-progressbar {
          display: flex;
          width: 100%;
          max-width: 15.625em;
          max-height: 15.625em;
          margin: 1.875em;
          div {
            display: flex;
          }
          .CircularProgressbar-path {
            stroke: $color-primary;
          }
          .CircularProgressbar-trail {
            stroke: $color-primary;
            opacity: 0.3;
          }
          > div > div > div {
            margin-top: 0em !important;
          }
          .community-circular-content {
            flex-direction: column;
            align-items: center;
            .community-circular-content-consumption {
              font-size: 0.75em;
              font-weight: 500;
            }
            .community-circular-content-value {
              font-size: 4.125em;
              font-weight: 700;
            }
            .community-circular-content-unit {
              font-size: 1.5625em;
              font-weight: 800;
            }
          }
        }
        .community-circular-mark {
          display: flex;
          p {
            font-size: 0.75em;
            font-weight: 500;
            &::before {
              width: 0.625em;
              height: 0.625em;
              display: inline-flex;
              margin-right: 0.4375em;
              content: ' ';
              background-color: $color-primary;
              border-radius: 100%;
            }
            &:first-child {
              margin-right: 0.75em;
              &::before {
                background-color: $color-primary-little-light;
              }
            }
          }
        }
      }
    }
    .community-details {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      gap: 1.25em;
      .community-price {
        display: flex;
        flex-direction: column;
        padding: 1.25em;
        background-color: rgb(var(--content-background-color));
        border-radius: 10px;
        .community-price-header {
          display: flex;
          font-weight: 700;
          font-size: 0.875em;
          justify-content: space-between;
          > div {
            display: flex;
            gap: 1.25em;
            :not(.active) {
              opacity: 0.3;
              transition: opacity 0.3s;
              &:hover {
                opacity: 0.7;
              }
            }
            * {
              cursor: pointer;
            }
          }
        }
        .community-price-content {
          display: flex;
          margin-top: 2.5em;
          align-items: center;
          > svg {
            font-size: 3.438em;
            color: $color-primary;
          }
          > div {
            display: flex;
            align-items: flex-end;
          }
          p {
            margin-left: 0.3125em;
            font-size: 2.8125em;
            line-height: 1em;
            font-weight: 600;
          }
          span {
            font-size: 0.875em;
            font-weight: 700;
          }
        }
      }
      .community-producers-consumers {
        display: flex;
        gap: 1.25em;
        position: relative;
        justify-content: center;
        align-items: center;
        .community-producers {
          display: flex;
          flex: 1 1;
          flex-direction: column;
          background-color: rgb(var(--content-background-color));
          border-radius: 10px;
          padding: 1.25em;
          .community-producers-header {
            display: flex;
            font-size: 0.875em;
            font-weight: 700;
          }
          .community-producers-content {
            display: flex;
            flex-direction: column;
            font-size: 0.875em;
            font-weight: 700;
            margin-top: 3.125em;
            .community-producers-generators {
              display: flex;
              gap: 1em;
              align-items: flex-end;
              font-size: 1.15em;
              .community-generator-svg {
                width: 2.8125em;
                height: 2.8125em;
                * {
                  fill: $color-warning;
                }
              }
              span {
                font-size: 2.8125em;
                line-height: 1em;
                font-weight: 600;
              }
            }
            .community-producers-value {
              margin-top: 2.375em;
              color: $color-warning;
              font-size: 1.0625em;
              font-weight: 700;
            }
            .community-producers-text {
              font-size: 1.0625em;
              font-weight: 500;
            }
          }
        }
        .community-consumers {
          display: flex;
          flex: 1 1;
          flex-direction: column;
          background-color: rgb(var(--content-background-color));
          border-radius: 10px;
          padding: 1.25em;
          .community-consumers-header {
            display: flex;
            justify-content: flex-end;
            font-size: 0.875em;
            font-weight: 700;
          }
          .community-consumers-content {
            display: flex;
            font-size: 0.875em;
            font-weight: 700;
            flex-direction: column;
            margin-top: 3.125em;
            align-items: flex-end;
            .community-consumers-house {
              display: flex;
              align-items: flex-end;
              flex-direction: row-reverse;
              gap: 1em;
              .community-house-svg {
                width: 2.8125em;
                height: 2.8125em;
                * {
                  fill: $color-success;
                }
              }
              span {
                font-size: 2.8125em;
                line-height: 1em;
                font-weight: 600;
              }
            }
            .community-consumers-value {
              margin-top: 2.375em;
              color: $color-success;
              font-size: 1.0625em;
              font-weight: 700;
            }
            .community-consumers-text {
              font-size: 1.0625em;
              font-weight: 500;
              text-align: right;
            }
          }
        }
        .community-circle {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          .community-circle-outside {
            position: absolute;
            width: 7.1875em;
            height: 7.1875em;
            background-color: rgb(var(--background-color));
            border-radius: 100%;
          }
          .community-circle-inner {
            position: absolute;
            width: 4.875em;
            height: 4.875em;
            background-color: rgb(var(--content-background-color));
            border-radius: 100%;
          }
          .community-arrow-svg {
            position: absolute;
            width: 4.875em;
            height: 4.875em;
          }
        }
      }
    }
    .community-chart {
      height: 380px;
      display: block;
      position: relative;
      background-color: rgb(var(--content-background-color));
      border-radius: 10px;
      padding: 1.25em;
      .community-chart-header {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

.community-tooltip-item {
  white-space: wrap;
  width: 75vw;
  max-width: 500px;
  margin: 0;
  padding: 1rem 2rem;
}

@media screen and (max-width: 1050px) {
  .kut-component-body {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .kut-component-body {
    font-size: 12px;
  }
}

@media screen and (max-width: 780px) {
  .kut-component-body {
    .kut-component-content {
      justify-content: center;
      .community-detailed-info {
        flex-direction: column;
      }
      .community-navbar {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > div {
          width: 100%;
          max-width: 400px;
          &:nth-child(2) {
            margin-top: 10px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .kut-component-body {
    .kut-component-content {
      .community-chart {
        height: 300px;
      }
      .community-navbar {
        > div {
          font-size: 1em;
          overflow-x: auto;
        }
        .inside-navbar {
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
