$color-transparent: transparent;
$color-white: #FFFFFF;
$color-default: #748596;
$color-default-dark: #57676F;
$color-default-light: #B9C2CA;
$color-default-little-light: #CFCFCF;
$color-default-middle-light: #D8D8D8;
$color-default-strong-light: #E5E5E5;
$color-primary: #29A9E1;
$color-primary-light: #80A9D1;
$color-primary-little-light: #E5F7FF;
$color-primary-strong-light: #F9F9FB;
// $color-primary-strong-light: #3A563D;
$color-secondary: #205F7B;
$color-secondary-light: #bcb8ff;
$color-success: #76C80F;
$color-success-dark: #447309;
$color-success-light: #E3FEC1;
$color-warning: #FF8C00;
$color-warning-light: #FFBF70;
$color-danger: #C83B0F;
$color-danger-light: #FFDACE;
$color-info: #008CFF;
$color-info-light: #66BAFF;
$color-purple: #635BFF;
$color-purple-light: #C7C4FF;
$color-border-grey: #6469732A;
$color-black: #000000;
$color-black-light: #18191C;
$color-black-little-light: #202225;
$color-black-strong-light: #96989D;

$font-main: Poppins;

:export {
    color-transparent: $color-transparent;
    color-white: $color-white;
    color-default: $color-default;
    color-default-dark: $color-default-dark;
    color-default-light: $color-default-light;
    color-default-little-light: $color-default-little-light;
    color-default-middle-light: $color-default-middle-light;
    color-default-strong-light: $color-default-strong-light;
    color-primary: $color-primary;
    color-primary-little-light: $color-primary-little-light;
    color-secondary: $color-secondary;
    color-secondary-light: $color-secondary-light;
    color-success: $color-success;
    color-success-dark: $color-success-dark;
    color-success-light: $color-success-light;
    color-warning: $color-warning;
    color-warning-light: $color-warning-light;
    color-danger: $color-danger;
    color-danger-light: $color-danger-light;
    color-info: $color-info;
    color-info-light: $color-info-light;
    color-purple: $color-purple;
    color-purple-light: $color-purple-light;
    color-black: $color-black;
    color-black-light: $color-black-light;
    color-black-little-light: $color-black-little-light;
    color-black-strong-light: $color-black-strong-light;
}